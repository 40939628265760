import { Reducer } from "react";
import { Logging } from "../types/Loggin";

interface State {
    loggings: Logging[]
}

const initialState: State = {
    loggings: []
}

export enum LoggingActions {
    PushLogging = 'PUSH_LOGGING',
    ClearLoggings = 'CLEAR_LOGGINGS',
}

const reducer: Reducer<State, { type: LoggingActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case LoggingActions.PushLogging:
            return { ...state, loggings: [payload, ...state.loggings] }
        case LoggingActions.ClearLoggings:
            return { ...state, loggings: [] }
        default:
            return state;
    }
}

export default reducer