import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	TableCell,
} from '@material-ui/core'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { authHeader } from '../../services/auth'
import { Setting } from '../../types/Setting'

import { addSnackbarNotification } from '../../services/snackbarNotification'
import EditableLabel from './EditableLabel'

const API_URL = process.env.REACT_APP_API_URL
const fetchSettings = async (): Promise<Setting[]> => {
	var settings = []
	var response = await axios.get<Paginated<Setting>>(
		API_URL + `settings/`,
		{
			headers: authHeader(),
		}
	)
	settings = response.data.results
	while (response.data.next) {
		response = await axios.get<Paginated<Setting>>(response.data.next, {
			headers: authHeader(),
		})
		settings = [...settings, response.data.results]
	}
	return settings
}

const updateSetting = async (id: number, body: Partial<Setting>) => {
	try {
		const response = await axios.patch<Paginated<Setting>>(
			API_URL + `settings/${id}/`,
			body,
			{
				headers: authHeader(),
			},
		)
		addSnackbarNotification({message: response.status.toString() + " Configuración actualizada", type: "success"})
	} catch (error) {
		addSnackbarNotification({message: "Hubo un error al actualizar la configuración", type: "error"})
	}

}

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const SettingsModal: React.FC<Props> = ({ open, onClose }) => {
	const [settings, setSettings] = useState<Setting[]>([])

	useEffect(() => {
		if (open) {
			const fetch = async () => {
				const tmp_settings = await fetchSettings()
				setSettings(tmp_settings)
			}
			fetch()
		}
	}, [open])

	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle> Configuración de parametros </DialogTitle>
			<DialogContent>
				<div className='max-h-96 overflow-auto'>
					<TableContainer>
						<Table>
							<TableBody>
								{settings.sort((a, b) => { return a.id - b.id }).map((setting) => {
									return (
										<TableRow>
											<TableCell>
												{setting.key}
											</TableCell>
											<TableCell>
												<EditableLabel
													label={'Valor'}
													value={setting.value}
													id={'value'}
													onSubmit={async (val) => {
														await updateSetting(setting.id, { value: val })
														const new_setting = { id: setting.id, key: setting.key, value: val }
														setSettings([...settings.filter((s) => s.id !== setting.id), new_setting])
													}}>
												</EditableLabel>
											</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default SettingsModal
