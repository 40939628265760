import axios from 'axios'
import { FtpServerActions } from '../reducers/FileScraperReducer'
import store from '../store'
import { FileListItem } from '../types/FileListItem'
import { FtpServer } from '../types/FtpServer'
import { authHeader } from './auth'
import { addSnackbarNotification } from './snackbarNotification'

const API_URL = process.env.REACT_APP_API_URL

export const fetchFtpServers = async () => {
	store.dispatch({
		type: FtpServerActions.RequestFtpServers,
		payload: {},
	})
	const state = store.getState().fileScraperReducer
	if (!(!state.next && state.ftpServers.length)) {
		if (state.next) {
			const response = await axios.get<Paginated<FtpServer>>(
				state.next,
				{ headers: authHeader() }
			)
			store.dispatch({
				type: FtpServerActions.AddFtpServers,
				payload: response.data,
			})
		} else {
			const { data } = await axios.get<FtpServer[]>(
				API_URL + 'ftp/get_ftps',
				{ headers: authHeader() }
			)

			console.log({data})

			store.dispatch({
				type: FtpServerActions.AddFtpServers,
				payload: data,
			})
		}
	}
}

export const createFtpServer = async (server: FtpServer) => {
	var retVal = false
	try {
		const { data } = await axios.post<FtpServer>(
			API_URL + 'file_origins/ftp_servers/',
			server,
			{ headers: authHeader() }
		)
		store.dispatch({
			type: FtpServerActions.AddFtpServer,
			payload: data,
		})
		addSnackbarNotification({ message: ` Servidor ${server.name} creado`, type: 'success' })
		retVal = true
	} catch (error) {
		addSnackbarNotification({ message: error.toString() + ` Hubo un error al crear el servidor ${server.name}`, type: 'error' })

	}
	return retVal

}

export const deleteFtpServer = async (id: number) => {
	await axios.delete<FtpServer>(
		API_URL + 'file_origins/ftp_servers/' + id,
		{ headers: authHeader() }
	)
	store.dispatch({
		type: FtpServerActions.DeleteFtpServer,
		payload: { id },
	})
}
export const fetchSingleFtpServer = async (id: number) => {
	store.dispatch({
		type: FtpServerActions.RequestFtpServer,
		payload: {},
	})
	const response = await axios.get<FtpServer>(
		API_URL + 'file_origins/ftp_servers/' + id,
		{ headers: authHeader() }
	)
	store.dispatch({
		type: FtpServerActions.AddFtpServer,
		payload: response.data,
	})
}

export const changeFtpServerAttributes = async (server: FtpServer, properties: Partial<FtpServer>) => {
	try {
		await axios.patch(API_URL + `file_origins/ftp_servers/${server.id}/`,
			properties,
			{ headers: authHeader() }
		)
		addSnackbarNotification({message: `Servidor ${server.name} actualizado`})
	} catch (error) {
		addSnackbarNotification({message: `Error al actualizar el servidor ${server.name}. \n${error}`, type: 'error'})
	}
}

export const listFtpServerPath = async (server: FtpServer, path: string) => {
	try {
		const {data} = await axios.get<FileListItem[]>(API_URL + `file_origins/ftp_servers/${server.id}/list_path/?path=${path}`,
			{ headers: authHeader() }
		)
		return data
	} catch (error) {
		addSnackbarNotification({message: `Error al listar ficheros del servidor ${server.name}. \n${error}`, type: 'error'})
	}
}

export const ingestMeasumentsFomFtpServer = async (server: FtpServer) => {
	try {
		await axios.get(API_URL + `file_origins/ftp_servers/${server.id}/ingest/`,
			{ headers: authHeader() }
		)
		addSnackbarNotification({message: `Importando mediciones de la ruta predeterminada del servidor ${server.name}.`})
	} catch (error) {
		addSnackbarNotification({message: `Error al listar ficheros del servidor ${server.name}. \n${error}`, type: 'error'})
	}
}

export const ingestMeasumentsFomFtpServerAndJson = async (server: FtpServer, data: Object) => {
	try {
		await axios.get(API_URL + `file_origins/ftp_servers/${server.id}/ingest/`,
			{ headers: authHeader(),
			params: {
				data: data
			} }
		)
		addSnackbarNotification({message: `Importando mediciones de la ruta predeterminada del servidor ${server.name}.`})
	} catch (error) {
		addSnackbarNotification({message: `Error al listar ficheros del servidor ${server.name}. \n${error}`, type: 'error'})
	}
}