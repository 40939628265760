import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
} from '@material-ui/core'
import { useForm } from '../../hooks/useForm'
import { createFtpServer, fetchFtpServers } from '../../services/ftpServer'
import { FtpServer } from '../../types/FtpServer'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const NewFtpServerModal: React.FC<Props> = ({ open, onClose }) => {
	const [values, , handleChange] = useForm({
		name: '',
		host: '',
		port: 21,
		path: '',
		user: '',
		password: '',
		set_active_mode: false,
		is_enabled: true,
	})

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
			<DialogContent className='p-2'>
				<div className='w-full flex'>
					<div className='w-1/2 p-2'>
						<TextField
							id='name'
							fullWidth
							color='primary'
							label='Nombre'
							size='medium'
							value={values.name}
							onChange={handleChange}
						/>
					</div>
					<div className='w-1/2 p-2'>
						<TextField
							id='host'
							fullWidth
							color='primary'
							label='Host'
							size='medium'
							value={values.host}
							onChange={handleChange}
						/>
					</div>
					<div className='w-1/2 p-2'>
						<TextField
							id='port'
							fullWidth
							color='primary'
							label='Puerto'
							size='medium'
							value={values.port}
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className='flex w-full'>
					<div className='w-1/2 p-2'>
						<TextField
							id='path'
							fullWidth
							color='primary'
							label='Ruta'
							size='medium'
							value={values.path}
							onChange={handleChange}
						/>
					</div>
					<div className='w-1/4 p-2'>
						<TextField
							id='set_active_mode'
							fullWidth
							color='primary'
							label='Modo Activo'
							size='medium'
							value={values.set_active_mode}
							onChange={handleChange}
						/>
					</div>
					<div className='w-1/4 p-2'>
						<TextField
							id='is_enabled'
							fullWidth
							color='primary'
							label='Habilitado'
							size='medium'
							value={values.is_enabled}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div>Credenciales</div>
				<div className='flex w-full'>
					<div className='w-1/2 p-2'>
						<TextField
							id='user'
							fullWidth
							color='primary'
							label='Usuario'
							size='medium'
							value={values.user}
							onChange={handleChange}
						/>
					</div>
					<div className='w-1/2 p-2'>
						<TextField
							id='password'
							fullWidth
							color='primary'
							label='Password'
							size='medium'
							value={values.password}
							onChange={handleChange}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={async () => {
						const val = await createFtpServer(values as FtpServer)
						if (val) {
							fetchFtpServers()
							onClose()
						}
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default NewFtpServerModal
