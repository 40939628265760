import { Reducer } from 'react'
import { Task } from '../types/Task'

interface State {
	tasks: Task[]
	isLoading: boolean
	next: null | string
	prev: null | string
}

const initialState: State = {
	tasks: [],
	isLoading: false,
	next: null,
	prev: null,
}


export enum TaskActions {
	RequestTasks = 'REQUEST_TASKS',
	RequestTask = 'REQUEST_TASK',
	AddTasks = 'ADD_TASKS',
	AddTask = 'ADD_TASK',
	DeleteTask = "DeleteTask"
}

const reducer: Reducer<State, { type: TaskActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case TaskActions.RequestTasks:
			return { ...state, isLoading: true }
		case TaskActions.AddTasks:
			return {
				...state,
				isLoading: false,
				next: payload.next,
				prev: payload.prev,
				tasks: [...state.tasks, ...payload.results],
			}
		case TaskActions.AddTask:
			return {
				...state,
				isLoading: false,
				tasks: [...state.tasks.filter((server) => server.id !== payload.id), payload],
			}
		case TaskActions.DeleteTask:
			return {
				...state,
				isLoading: false,
				tasks: [...state.tasks.filter((server) => server.id !== payload.id)],
			}
		default:
			return state
	}
}

export default reducer
