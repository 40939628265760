import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import EditableLabel from "../../components/shared/EditableLabel";
import QuestionModal from "../../components/shared/QuestionModal";
import { useAppSelector } from "../../hooks/storeHooks";
import {
  changeFtpServerAttributes,
  deleteFtpServer,
  fetchFtpServers,
  fetchSingleFtpServer,
} from "../../services/ftpServer";
import useStyles from "../../styles/appStyles";
import { FtpServer } from "../../types/FtpServer";
import ExploreFtpServerModal from "./ExploreFtpServerModal";
import InjectFiles from "./InjectFiles";
import NewFtpServerModal from "./NewFtpServerModal";

const Servers: React.FC = () => {
  const [targetFtpServer, setTargetFtpServer] = useState<FtpServer>(null);
  const [newFtpServerModalOpen, setNewFtpServerModalOpen] = useState(false);
  const [exploreFtpServerModalOpen, setExploreFtpServerModalOpen] =
    useState(false);
  const [deleteFtpServerModal, setDeleteFtpServerModal] = useState(false);
  const [signalIngest, setSignal] = useState(false);
  const ftpServers = useAppSelector((s) => s.fileScraperReducer.ftpServers);
  const [newMeasurements, setNewMeasurements] = useState([]);
  const [openIngest, setOpenIngest] = useState(false);
  const [currentMeasurement, setCurrentMeasurement] = useState([]);
  const [signalCloseIngest, setSignalCloseIngest] = useState(false);
  const [signalMyFtp] = useState(false);

  const updateFtpServerPath = async (path) => {
    console.log(signalCloseIngest)
    targetFtpServer &&
      changeFtpServerAttributes(targetFtpServer, { path }).then(() => {
        fetchSingleFtpServer(targetFtpServer.id);
      });
  };
  // const ingestMeasurements = async () => {
  //   targetFtpServer && ingestMeasumentsFomFtpServer(targetFtpServer);
  // };

  useEffect(() => {
    fetchFtpServers();
  }, []);

  function receiveSignal() {
    setSignal(true);
  }

  function receiveSignalIngest() {
    setOpenIngest(false);
    setSignalCloseIngest(false);
  }

  function receiveNewMeasurements(myMeasurements) {
    setExploreFtpServerModalOpen(false);
    setNewMeasurements(myMeasurements);

    if (myMeasurements) {
      setCurrentMeasurement(myMeasurements);
      setOpenIngest(true);
    }
  }

  useEffect(() => {
    if (!exploreFtpServerModalOpen && !openIngest) {
      setTargetFtpServer(null);
    }
  }, [exploreFtpServerModalOpen]);

  const classes = useStyles(true)();
  return (
    <>
      <div className={`${classes.bodyContent} p-4 overflow-auto`}>
        <div className="w-full text-center mb-5">
          Servidores Ftp{" "}
        </div>
        <TableContainer component={Paper}>
          <Table className="w-full" aria-label="customized table">
            <TableHead style={{ backgroundColor: "gray" }}>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Habilitado</TableCell>
                <TableCell>Host</TableCell>
                <TableCell>Puerto</TableCell>
                <TableCell>Ruta</TableCell>
                <TableCell>Usuario</TableCell>
                <TableCell>Tipo de conexión</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ftpServers
                .sort((a, b) => parseInt(a.name) - parseInt(b.name))
                .map((server) => (
                  <TableRow key={server.id}>
                    <TableCell>{server.id}</TableCell>
                    <TableCell>
                      <EditableLabel
                        value={server.name}
                        id="name"
                        label="Nombre"
                        // permissions={[
                        //     Permissions.ModifyConditions,
                        // ]}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={server.is_enabled}
                        size="medium"
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell>
                      <EditableLabel
                        value={server.host}
                        id="host"
                        label="Host"
                        // permissions={[
                        //     Permissions.ModifyConditions,
                        // ]}
                      />
                    </TableCell>
                    <TableCell>
                      <EditableLabel
                        value={server.port}
                        id="port"
                        label="Puerto"
                        // permissions={[
                        //     Permissions.ModifyConditions,
                        // ]}
                      />
                    </TableCell>
                    <TableCell>
                      <EditableLabel
                        value={server.path}
                        id="path"
                        label="Ruta"
                        // permissions={[
                        //     Permissions.ModifyConditions,
                        // ]}
                      />
                    </TableCell>
                    <TableCell>
                      <EditableLabel
                        value={server.user}
                        id="user"
                        label="Usuario"
                        // permissions={[
                        //     Permissions.ModifyConditions,
                        // ]}
                      />
                    </TableCell>
                    <TableCell>
                      <EditableLabel
                        value={server.connection_type}
                        id="connection_type"
                        label="Tipo de conexión"
                        // permissions={[
                        //     Permissions.ModifyConditions,
                        // ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <NewFtpServerModal
          open={newFtpServerModalOpen}
          onClose={() => {
            setNewFtpServerModalOpen(false);
          }}
        ></NewFtpServerModal>
        {targetFtpServer && signalMyFtp && (
          <ExploreFtpServerModal
            open={exploreFtpServerModalOpen}
            onClose={() => {
              setExploreFtpServerModalOpen(false);
            }}
            ftpServer={targetFtpServer}
            updatePath={updateFtpServerPath}
            signalState={signalIngest}
            changeSignal={receiveSignal.bind(this)}
            newMeas={newMeasurements}
            receiveMeasurements={receiveNewMeasurements.bind(this)}
          ></ExploreFtpServerModal>
        )}
        <QuestionModal
          onYes={() => deleteFtpServer(targetFtpServer.id)}
          open={deleteFtpServerModal}
          text={`¿Seguro que desea eliminar el servidor ${targetFtpServer?.name}?`}
          onClose={() => setDeleteFtpServerModal(false)}
          snackbarNotificationText={`Servidor ${targetFtpServer?.name} eliminado`}
        ></QuestionModal>
        <InjectFiles
          open={openIngest}
          ftpServer={targetFtpServer}
          newMeasurements={currentMeasurement}
          closeIngest={receiveSignalIngest.bind(this)}
        ></InjectFiles>
      </div>
    </>
  );
};
export default Servers;
