import { Reducer } from 'react'

interface State {
	user: User
	users: User[]
	token: any
	isLoading: boolean
	isEditing: boolean
}

const initialState: State = {
	user: null,
	token: null,
	isLoading: false,
	isEditing: false,
	users: [],
}

export enum AuthActions {
	RequestLogin = 'REQUEST_LOGIN',
	SetUser = 'SET_USER',
	SetUsers = 'SET_USERS',
	SetToken = 'SET_TOKEN',
	Logout = 'LOGOUT',
	SetIsEditing = 'SET_IS_EDITING',
}

const reducer: Reducer<State, { type: AuthActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case AuthActions.RequestLogin:
			return { ...state, isLoading: true }
		case AuthActions.SetToken:
			return { ...state, isLoading: false, token: payload }
		case AuthActions.SetUser: {
			localStorage.setItem('user', JSON.stringify(payload));
			return { ...state, user: payload };
		}
		case AuthActions.SetUsers:
			return { ...state, users: payload }
		case AuthActions.Logout:
			return { ...state, token: null }
		case AuthActions.SetIsEditing:
			return { ...state, isEditing: payload }
		default:
			return state
	}
}

export default reducer
