import { combineReducers } from 'redux'
import authReducer from './AuthReducer'
import timeReducer from './TimeReducer'
import measurmentReducer from './MeasurementReducer'
import minesReducer from './MinesReducer'
import inversionReducer from './InversionReducer'
import templateReducer from './TemplateReducer'
import snackbarNotificationReducer from './SnackbarReducer'
import loggingReducer from './LoggingReducer'
import fileScraperReducer from './FileScraperReducer'
import taskReducer from './TaskReducer'
import selectedMeasurement from './SelectedMeasurement'
import intervalReducer from './IntervalScheduleReducer'
import repositoryElementReducer from './RepositoryElementReducer'
import observationReducer from './ObservationReducer'
import scaleReducer from './ScaleReducer'
import pngVtkReducer from './pngVtkReducer'
import saveVtkReducer from './saveVtkReducer'


const rootReducer = combineReducers({
	authReducer,
	timeReducer,
	measurmentReducer,
	minesReducer,
	inversionReducer,
	templateReducer,
	snackbarNotificationReducer,
	loggingReducer,
	fileScraperReducer,
	taskReducer,
	selectedMeasurement,
	intervalReducer,
	repositoryElementReducer,
	observationReducer,
	scaleReducer,
	pngVtkReducer,
	saveVtkReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
