import {
  SET_SELECTED_ZIP_FILE,
  SET_MINE,
  SET_UPLOADED_BY,
} from '../actions/saveVtkActions';

const initialState = {
  zipFile: null,
  mine: null,
  uploadedBy: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ZIP_FILE:
      return {
        ...state,
        zipFile: action.payload,
      };
    case SET_MINE:
      return {
        ...state,
        mine: action.payload,
      };
    case SET_UPLOADED_BY:
      return {
        ...state,
        uploadedBy: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
