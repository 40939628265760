import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	TableCell,
} from '@material-ui/core'
import { useAppSelector } from '../../hooks/storeHooks'
import { LoggingActions } from '../../reducers/LoggingReducer'
import store from '../../store'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const LoggingViewerModal: React.FC<Props> = ({ open, onClose }) => {
	const loggings = useAppSelector((s) => s.loggingReducer.loggings)

	// useEffect(() => {
	// 	open && setValues({ name: '' })
	// }, [open])

	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle> Visor de Logs</DialogTitle>
			<DialogContent>
				<div className='max-h-96 overflow-auto'>
					<TableContainer>
						<Table>
							<TableBody>
								{loggings.map((item, index) => {
									return (
										<TableRow key={index}>
											<TableCell>
												{item.datetime.toString()}
											</TableCell>
											<TableCell>
												{item.message_type}
											</TableCell>
											<TableCell>
												{item.message}
											</TableCell>											
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancelar</Button>
				<Button onClick={() => {
					store.dispatch({
						type: LoggingActions.ClearLoggings,
					})
				}}>
					Borrar Logs</Button>
			</DialogActions>
		</Dialog>
	)
}
export default LoggingViewerModal
