import {
	Card,
	Button,
} from '@material-ui/core'
import { useEffect } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import { fetchAllInversions } from '../../services/inversion'
import { fetchMines } from '../../services/mines'
import MineExpandable from './MineExpandable'

const MeasurementSideList: React.FC = () => {
	const mines = useAppSelector((s) => s.minesReducer.mines)

	useEffect(() => {
		fetchMines()
		fetchAllInversions()
	}, [])

	return (
		<div className='h-full overflow-auto'>
			{/* <Button variant='contained' color='primary'>
				Nueva medición
			</Button> */}
			<div className='mt-2'>
				<Card>
					{mines.map((m, index) => (
						<MineExpandable
							mine={m}
							key={m.id}
							index={index}
						></MineExpandable>
					))}
				</Card>
			</div>
		</div>
	)
}

export default MeasurementSideList
