import axios from 'axios'
import { ObservationSetActions } from '../reducers/ObservationReducer'
import store from '../store'
import { Observation } from '../types/Observation'
import { ObservationArea } from '../types/ObservationArea'
import { ObservationSet } from '../types/ObservationSet'
import { ObservationType } from '../types/ObservationType'
import { ObservationVariation } from '../types/ObservationVariation'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const fetchObservationSets = async () => {
	const response = await axios.get<Paginated<ObservationSet>>(API_URL + `observation_sets/`, {
		headers: authHeader(),
	})
	store.dispatch({ type: ObservationSetActions.SetObservationSets, payload: response.data.results })
}

export const selectObservationSet = (obs_set_id: number) => {
    store.dispatch({ type: ObservationSetActions.SetSelectedObservationSet, payload: obs_set_id })
}

export const selectObservationArea = (obs_area: ObservationArea) => {
    store.dispatch({ type: ObservationSetActions.SetSelectedObservationArea, payload: obs_area })
}

export const fetchObservationTypes = async () => {
	const response = await axios.get<Paginated<ObservationType>>(API_URL + `observation_types/`, {
		headers: authHeader(),
	})
	store.dispatch({ type: ObservationSetActions.SetObservationTypes, payload: response.data.results })
}

export const fetchObservationAreas = async () => {
	const response = await axios.get<Paginated<ObservationArea>>(API_URL + `observation_areas/`, {
		headers: authHeader(),
	})
	store.dispatch({ type: ObservationSetActions.SetObservationAreas, payload: response.data.results })
}

export const fetchObservations = async () => {
	const response = await axios.get<Paginated<Observation>>(API_URL + `observations/`, {
		headers: authHeader(),
	})
	store.dispatch({ type: ObservationSetActions.SetObservations, payload: response.data.results })
}

export const fetchObservationVariations = async () => {
	const response = await axios.get<Paginated<ObservationVariation>>(API_URL + `observation_variations/`, {
		headers: authHeader(),
	})
	store.dispatch({ type: ObservationSetActions.SetObservationVariations, payload: response.data.results })
}