import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	reconnect: () => void
}

const ReconnectModal: React.FC<Props> = ({ open, onClose, reconnect }) => {
	return (
		<Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'}>
			<DialogTitle> Servidor desconectado</DialogTitle>
			<DialogContent>
				<div className='max-h-96 overflow-auto'>

				</div>
			</DialogContent>
			<DialogActions>
				<Button 
				color='primary'
				variant='contained'
				onClick={() => {
					reconnect()
				}}>
					Reconectar
				</Button>
				<Button 
				disabled={true}
				variant='outlined'
				color='secondary'
				onClick={onClose}>
					Cancelar
					</Button>
			</DialogActions>
		</Dialog>
	)
}
export default ReconnectModal
