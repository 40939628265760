export const SET_SELECTED_ZIP_FILE = 'SET_SELECTED_ZIP_FILE';
export const SET_MINE = 'SET_MINE';
export const SET_UPLOADED_BY = 'SET_UPLOADED_BY';

export const setSelectedZipFile = (zipFile) => ({
  type: SET_SELECTED_ZIP_FILE,
  payload: zipFile,
});

export const setMine = (mine) => ({
  type: SET_MINE,
  payload: mine,
});

export const setUploadedBy = (uploadedBy) => ({
  type: SET_UPLOADED_BY,
  payload: uploadedBy,
});
