import { Reducer } from 'react'
import { ObservationSet } from '../types/ObservationSet'
import { ObservationType } from '../types/ObservationType'
import { ObservationArea } from '../types/ObservationArea'
import { Observation } from '../types/Observation'
import { ObservationVariation } from '../types/ObservationVariation'

interface State {
	observationSets: ObservationSet[],
	selectedObservationSetId: number,
	observationTypes: ObservationType[],
	observationAreas: ObservationArea[],
	selectedObservationArea: ObservationArea,
	observations: Observation[],
	observationVariations: ObservationVariation[],
	// observationsPageCount: number,
	// observationsCurrentPageNumber: number,
}

const initialState: State = {
	observationSets: [],
	selectedObservationSetId: null,
	observationTypes: [],
	observationAreas: [],
	selectedObservationArea: null,
	observations: [],
	observationVariations: [],
	// observationsPageCount: null,
	// observationsCurrentPageNumber: null,
}

export enum ObservationSetActions {
	SetObservationSets = 'SET_OBSERVATION_SETS',
	SetSelectedObservationSet = 'SET_SELECTED_OBSERVATION_SET',
	SetObservationTypes = 'SET_OBSERVATION_TYPES',
	SetObservationAreas = 'SET_OBSERVATION_AREAS',
	SetSelectedObservationArea = 'SET_SELECTED_OBSERVATION_AREA',
	SetObservations = 'SET_OBSERVATIONS',
	SetObservationVariations = 'SET_OBSERVATION_VARIATIONS',
}

const reducer: Reducer<State, { type: ObservationSetActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case ObservationSetActions.SetObservationSets:
			return {
				...state,
				observationSets: payload,
			}
		case ObservationSetActions.SetSelectedObservationSet:
			return {
				...state,
				selectedObservationSetId: payload,
			}
		case ObservationSetActions.SetObservationTypes:
			return {
				...state,
				observationTypes: payload,
			}
		case ObservationSetActions.SetObservationAreas:
			return {
				...state,
				observationAreas: payload,
			}
		case ObservationSetActions.SetSelectedObservationArea:
			return {
				...state,
				selectedObservationArea: payload,
			}
		case ObservationSetActions.SetObservations:
			return {
				...state,
				observations: payload,
			}
		case ObservationSetActions.SetObservationVariations:
			return {
				...state,
				observationVariations: payload,
			}
		default:
			return state
	}
}

export default reducer
