import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import * as d3 from 'd3'
import { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import { useSize } from '../../hooks/useSize'
import { getVtkFile, selectInversion } from '../../services/inversion'

const V_AXIS_WIDTH = 80
const H_AXIS_HEIGHT = 20

interface pointCoords {
  x: number,
  y: number
}

interface areaSelection {
  start: pointCoords,
  end: pointCoords
}

const InversionAlertViewer: React.FC<{ gridWidth: number, gridHeight: number }> = ({ gridWidth, gridHeight }) => {
  const inversion = useAppSelector((s) => s.inversionReducer.selectedInversion)
  const inversions = useAppSelector((s) => s.inversionReducer.inversions)
  const measurements = useAppSelector((s) => s.measurmentReducer.measurements)
  const [measurement, setMeasurement] = useState(null)
  const templates = useAppSelector((s) => s.templateReducer.templates)
  const [template, setTemplate] = useState(null)
  const vtkFile = useAppSelector((s) => s.inversionReducer.vtkFiles[inversion?.id])
  const vtkImageFile = useAppSelector(
    (s) => s.inversionReducer.vtkFileImage
  )
  const [data] = useState<{
    points: { x: number; y: number; z: number }[]
    min: { x: number; y: number;[key: string]: number }
    max: { x: number; y: number;[key: string]: number }
    polys: { x: number; y: number }[][]
    layers: { [key: string]: number[] }
  }>(null)
  const [container, width, height] = useSize<HTMLDivElement>()
  const chartRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [scaleInitialValue] = useState(5)
  const [scaleFactorValue] = useState(1.36)
  const [selectedTiles, setSelectedTiles] = useState<[number, number][]>([])
  const [positionUnderPointer, setPositionUnderPointer] = useState<pointCoords>(null)
  const [selectedArea, setSelectedArea] = useState<areaSelection>(null)
  const selectedObservationArea = useAppSelector((s) => s.observationReducer.selectedObservationArea)
  useEffect(() => {
    setSelectedTiles(selectedTiles.filter(([x, y]) => x < gridWidth && y < gridHeight))
  }, [gridWidth, gridHeight])

  useEffect(() => {
    inversion && setMeasurement(measurements.find((m) => m.id === inversion.measurement))
    inversion && setTemplate(templates.find((m) => m.id === inversion.template))
  }, [inversion])

  useEffect(() => {
    setLoading(true)
    if(inversion != null){
      getVtkFile(inversion, null, null, null, null, null, null).then(() => setLoading(false))
    }
  }, [inversion])

  useEffect(() => {
    if (selectedObservationArea) {
      const tmp = {
        start: { x: selectedObservationArea.object[0].x1, y: selectedObservationArea.object[0].y1 },
        end: { x: selectedObservationArea.object[0].x2, y: selectedObservationArea.object[0].y2 }
      }
      selectedObservationArea && setSelectedArea(tmp)
      // console.log("selectedObservationArea", selectedObservationArea)
      // console.log("selectedArea",selectedArea)
      // console.log("tmp",tmp)
    }
  }, [selectedObservationArea])

  useEffect(() => {
    const loadData = async () => {
      //const parsedFile = await parseVtkFile(vtkFile)
      //const layerNames = Object.keys(parsedFile.layers)
      //const i = layerNames.indexOf(" Resistivity/Ohmm double 1")
      // console.log(layerNames)
      //setLayer(layerNames[i])
      //setData(vtkFile)

      window.dispatchEvent(new Event('resize'))
    }
    if (vtkFile) {
      loadData()
    }
  }, [vtkFile])

  useEffect(() => {
    setLoading(true)
    console.log('kasfjaspfo')
    getVtkFile(inversion, null, null, null, null, false, null).then(() => setLoading(false))
  }, [inversion])

  const xScale = d3
    .scaleLinear()
    .domain([data?.min.x, data?.max.x])
    .range([V_AXIS_WIDTH, width])
  const yScale = d3
    .scaleLinear()
    .domain([data?.min.y, data?.max.y])
    .range([height - H_AXIS_HEIGHT, 0])


  const color_steps = () => {
    const inititalValue = scaleInitialValue
    const factor = scaleFactorValue
    const stepCount = 17
    var steps = []

    const colors = [{ j: 0, r: '00', g: '00', b: '80' },
    { j: 1, r: '00', g: '00', b: '79' },
    { j: 2, r: '00', g: '00', b: 'D3' },
    { j: 3, r: '00', g: '00', b: 'FF' },
    { j: 4, r: '00', g: '80', b: 'FF' },
    { j: 5, r: '00', g: 'FF', b: 'FF' },
    { j: 6, r: '00', g: 'C0', b: '80' },
    { j: 7, r: '00', g: 'FF', b: '00' },
    { j: 8, r: '00', g: '80', b: '00' },
    { j: 9, r: '80', g: 'C0', b: '00' },
    { j: 10, r: 'FF', g: 'FF', b: '00' },
    { j: 11, r: 'BF', g: '80', b: '00' },
    { j: 12, r: 'FF', g: '80', b: '00' },
    { j: 13, r: 'FF', g: '00', b: '00' },
    { j: 14, r: 'D3', g: '00', b: '00' },
    { j: 15, r: '84', g: '00', b: '40' },
    { j: 16, r: '60', g: '00', b: '60' },]
    for (var i = 0; i < stepCount; i++) {
      const tmpLog = Math.log10(inititalValue) + (i * Math.log10(factor))
      steps.push({
        logValue: tmpLog,
        value: 10 ** tmpLog,
        r: colors[i].r,
        g: colors[i].g,
        b: colors[i].b,
      })
    }
    return steps
  }

  const step = (param: number) => {

    var foundIndex = color_steps().findIndex((e) => {
      return e.value >= param
    })
    if (foundIndex === -1) {
      foundIndex = color_steps().length - 1
    }
    return foundIndex
  }

  const color = (param: number, inverse = false) => {
    const steps = [{ value: 0.0000, color: '#000260' },
    { value: 5.0000, r: '00', g: '02', b: '85' },
    { value: 6.1000, r: '01', g: '00', b: 'B1' },
    { value: 7.4420, r: '01', g: '01', b: 'C6' },
    { value: 9.0792, r: '03', g: '65', b: 'C7' },
    { value: 11.0767, r: '03', g: 'CA', b: 'CA' },
    { value: 13.5135, r: '0B', g: '96', b: '67' },
    { value: 16.4865, r: '07', g: 'CA', b: '00' },
    { value: 20.1136, r: '00', g: '65', b: '00' },
    { value: 24.5385, r: '66', g: '99', b: '00' },
    { value: 29.9370, r: '66', g: '9A', b: '01' },
    { value: 36.5232, r: 'CA', g: 'C9', b: '01' },
    { value: 44.5583, r: '93', g: '66', b: '00' },
    { value: 54.3611, r: 'CC', g: '67', b: '00' },
    { value: 66.3205, r: 'C4', g: '02', b: '04' },
    { value: 80.9110, r: 'A6', g: '01', b: '00' },
    { value: 98.7115, r: '64', g: '01', b: '36' },
    { value: 1000.000, r: '4D', g: '00', b: '52' },]
    var found = steps[step(param)]
    return `#${found.r}${found.g}${found.b}`
  }

  const inversionLabel = (inversion: Inversion) => {
    const array = measurements.find((m) => m.id === inversions.find((i) => i.id === inversion.id).measurement)["stg_file"].split("/")
    const label = array[array.length - 1]
    return label + "-" + templates.find((t) => t.id === inversion.template).label
  }
  const getCurrentCoords = (e) => {
    const dim = chartRef.current.getBoundingClientRect()
    const x = xScale.invert(e.clientX - dim.x)
    const y = yScale.invert(e.clientY - dim.y)
    setPositionUnderPointer({ x: x, y: y })
  }

  const selectedAreaWidth = () => {
    const width = selectedArea?.end ?
      xScale(selectedArea?.end?.x - selectedArea?.start?.x) :
      xScale(positionUnderPointer?.x - selectedArea?.start?.x)
    return width - V_AXIS_WIDTH
  }

  const selectedAreaHeight = () => {
    return selectedArea?.end ?
      yScale(selectedArea?.end?.y - selectedArea?.start?.y) :
      yScale(positionUnderPointer?.y - selectedArea?.start?.y)
  }

  return (
    <div>
      {loading ? (
        <div
          style={{ minHeight: `${height}px` }}
          className='text-center'
        >
          Cargando
        </div>
      ) : (
        <>
          <div key={"iav_1"} className='mb-1 flex items-center'>
            <div key={"iav_2"} className="w-48">
              <FormControl fullWidth variant="filled">
                <InputLabel id="inversion-label">Inversion</InputLabel>
                <Select labelId="inversion-label" fullWidth defaultValue={""} value={inversion?.id} onChange={e => selectInversion(inversions.find(i => i.id === +e.target.value))}>
                  {inversions.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {inversionLabel(e)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* <TextField
              variant="outlined"
              type='number'
              value={scaleInitialValue}
              label="Valor inicial"
              onChange={(e) => setScaleInitialValue(+e.target.value)}
            >
            </TextField>
            <TextField
              variant="outlined"
              type='number'
              value={scaleFactorValue}
              label="Factor"
              onChange={(e) => setScaleFactorValue(+e.target.value)}
            >
            </TextField> */}
            {measurement && ('   Día:' + measurement.dt.split('T')[0] + ' Fichero:' + measurement.stg_file.split('/')[measurement.stg_file.split('/').length - 1] +
              ' Template:' + template.label)
            }
            {/* <div className=" flex-1"></div>
            <Button color="primary" variant="contained">
              Guardar
            </Button> */}
          </div>
          <div ref={container}>
          <img src={`data:;base64,${vtkImageFile}`} width="100%" height="auto" style={{ display: !!vtkImageFile ? 'inline' : 'none', maxHeight: '60vh',}} />
          </div>
        </>
      )}
    </div>
  )
}
export default InversionAlertViewer
