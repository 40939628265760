import { Reducer } from 'react'
import { IntervalSchedule } from '../types/IntervalSchedule'

interface State {
	intervals: IntervalSchedule[]
	isLoading: boolean
	next: null | string
	prev: null | string
}

const initialState: State = {
	intervals: [],
	isLoading: false,
	next: null,
	prev: null,
}


export enum IntervalScheduleActions {
	RequestIntervalSchedules = 'REQUEST_INTERVALS',
	RequestIntervalSchedule = 'REQUEST_INTERVAL',
	AddIntervalSchedules = 'ADD_INTERVALS',
	AddIntervalSchedule = 'ADD_INTERVAL',
	DeleteIntervalSchedule = 'DELETE_INTERVAL'
}

const reducer: Reducer<State, { type: IntervalScheduleActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case IntervalScheduleActions.RequestIntervalSchedules:
			return { ...state, isLoading: true }
		case IntervalScheduleActions.AddIntervalSchedules:
			return {
				...state,
				isLoading: false,
				next: payload.next,
				prev: payload.prev,
				intervals: [...state.intervals, ...payload.results],
			}
		case IntervalScheduleActions.AddIntervalSchedule:
			return {
				...state,
				isLoading: false,
				intervals: [...state.intervals.filter((server) => server.id !== payload.id), payload],
			}
		case IntervalScheduleActions.DeleteIntervalSchedule:
			return {
				...state,
				isLoading: false,
				intervals: [...state.intervals.filter((server) => server.id !== payload.id)],
			}
		default:
			return state
	}
}

export default reducer
