import axios from 'axios'
import { MineActions } from '../reducers/MinesReducer'
import store from '../store'
import { authHeader } from './auth'
import { Mines } from '../types/Mines'

const API_URL = process.env.REACT_APP_API_URL

export const fetchMines = async () => {
	store.dispatch({
		type: MineActions.RequestMines,
		payload: {},
	})
	const state = store.getState().minesReducer
	if (!(!state.next && state.mines.length)) {
		if (state.next) {
			const response = await axios.get<Paginated<Mines>>(
				state.next,
				{ headers: authHeader() }
			)
			store.dispatch({
				type: MineActions.AddMines,
				payload: response.data,
			})
		} else {
			const response = await axios.get<Paginated<Mines>>(
				API_URL + 'minas/',
				{ headers: authHeader() }
			)
			store.dispatch({
				type: MineActions.AddMines,
				payload: response.data,
			})
		}

		// store.dispatch({
		// 	type: "SET_SELECTEDMEASUREMENT",
		// 	payload: 0,
		// });
	}
}