import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { fetchInversionStatuses } from "../../services/inversion";
import { fetchMeasurementStatuses } from "../../services/measurement";
import { fetchConfigKeys } from "../../services/template";
import useStyles from "../../styles/appStyles";
import MeasurementSideList from "./MeasurementSideList";
import FormVTK from "./FormVTK";
import PngVtkViewer from "./pngVtkViewer";
import { useAppSelector, useAppDispatch } from "../../hooks/storeHooks";
import { mdiCloudUpload, mdiInformation, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";

const Monitoring: React.FC = () => {
  useEffect(() => {
    fetchMeasurementStatuses();
    fetchInversionStatuses();
    fetchConfigKeys();
  }, []);
  const classes = useStyles(true)();
  const [toggle, setToggle] = useState(false)
  const [formVTK, setFormVTK] = useState(false);
  const selectedInstallation = useAppSelector((s) => s.measurmentReducer.selectedInstallation);

  // ajaa
  // function select(state) {
  //   return state.some.deep.property
  // }
  
  // let currentValue
  // function handleChange() {
  //   let previousValue = currentValue
  //   currentValue = select(store.getState())
    
  //   if (previousValue !== currentValue) {
  //     console.log('Some deep nested property changed from', previousValue, 'to', currentValue)
  //   }
  // }
  
  // let unsubscribe = store.subscribe(handleChange)
  // handleChange()
  // ajaa
  
  return (
    <div className={`flex ${classes.bodyContent} `}>
      {formVTK && (
        <FormVTK
          open={formVTK}
          onClose={() => {
            setFormVTK(false);
          }}
        ></FormVTK>
      )}
      <div className="w-1/5 bg-gray-100 p-2 flex flex-col" style={{ position: 'relative', maxWidth: toggle ? '0%' : '20%', transition: "all 0.5s ease 0s" }}>
        {
          !toggle ? (<Button
            color="primary"
            className="m-2"
            variant="contained"
            onClick={() => {
              setFormVTK(true);
            }}
            title="Agregar VTK"
          >
            <Icon
              path={mdiCloudUpload}
              color='white'
              className="mr-2"
              size={1}
            ></Icon> <span>Subir mediciones</span>
          </Button>
          ) : ''
        }

        <button onClick={() => setToggle(!toggle)} style={{ position: 'absolute', right: '-18px', top: '50%', background: '#242363', zIndex: 1, padding: '5px', borderRadius: '50px' }}>
          <Icon path={!toggle ? mdiChevronLeft : mdiChevronRight} size={1} color='white' />
        </button>
        <MeasurementSideList></MeasurementSideList>
      </div>
      <div className="h-full w-full">
        {
          selectedInstallation ? (<PngVtkViewer />)
            : (
              <p style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                margin: 'auto',
                justifyContent: 'center',
                fontSize: 'large'
              }}>

                <Icon
                  path={mdiInformation}
                  className='w-10'
                  color='blue'
                ></Icon>
                Selecciona una instalación del panel lateral
              </p>
            )
        }
        {/*  */}
      </div>
    </div>
  );
};

export default Monitoring;
