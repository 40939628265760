import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { useState } from 'react'
import Icon from '@mdi/react'
import { FtpServer } from '../../types/FtpServer'
import { mdiDatabaseImport } from '@mdi/js'
import { ingestMeasumentsFomFtpServerAndJson } from '../../services/ftpServer'
import { addSnackbarNotification } from '../../services/snackbarNotification'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	ftpServer?: FtpServer
	newMeasurements: any
	closeIngest: Function
}

const InjectFiles: React.FC<Props> = ({ open, onClose, ftpServer, newMeasurements, closeIngest }) => {

	const [isLoadingFiles] = useState(false)
	var myListEl = {}
	var myListDis = {}
	/* const reload = async () => {
		setIsLoadingFiles(true)
		const tmp_files = await listFtpServerPath(server, currentPath)
		if (tmp_files != null) {
			get_new_measurements(measurements, tmp_files)
		}
		tmp_files?.length && setFiles(tmp_files)
		setIsLoadingFiles(false)
	} */

	/* useEffect(() => {
		server && reload()
	}, [currentPath, server]);
 */

	function numberElectrodes(e, file) {
		myListEl[file] = e.target.value
	}

	function distanceElectrodes(e, file){
		myListDis[file] = e.target.value
	}

	function checkArray(nElectrodes, dElectrodes){
		let checkD = Object.values(dElectrodes).includes("")
		let checkE = Object.values(nElectrodes).includes("")
		let leD = Object.values(dElectrodes).length
		let leN = Object.values(nElectrodes).length
		let isEmptyN = Object.keys(nElectrodes).length === 0
		let isEmptyD = Object.keys(dElectrodes).length === 0
		let lenM = Object.keys(newMeasurements).length
		let lenD = Object.keys(dElectrodes).length
		let lenN = Object.keys(nElectrodes).length

		if(isEmptyN || isEmptyD){
			return false
		}
		if (leD !== leN){
			return false
		}
		if (checkD || checkE){
			return false
		}
		if(lenM !== lenN || lenM !== lenD){
			return false
		}

		return true
	}

	function createJsonToSend(nElectrodes, dElectrodes){
		checkArray(nElectrodes, dElectrodes)
		if(!checkArray(nElectrodes, dElectrodes)){
			addSnackbarNotification({
				message: 'Ha ocurrido un error: DEBE RELLENAR TODOS LOS CAMPOS PARA TODAS LAS MEDICIONES',
				type: 'error',
			})
		}
		else{
			addSnackbarNotification({
				message: 'Importando Mediciones',
				type: 'success',
			})
			var jsonToSend = {
				'filename' : Object.keys(nElectrodes),
				'nElectrodes': Object.values(nElectrodes),
				'dElectrodes': Object.values(dElectrodes)
			}
			let dataSend = null;
			dataSend = JSON.stringify(jsonToSend)
			ingestMeasumentsFomFtpServerAndJson(ftpServer, dataSend)
			closeIngest()
		}
		
	}
	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
			<DialogContent className='p-2'>
				<div className='w-full flex'>
					<div className='w-1/2 p-2'>
						<h1>Importando Mediciones</h1>
					</div>
					<div className='w-1/4 p-2'>
						{isLoadingFiles && (
							<div className='w-full'>
								<LinearProgress />
							</div>
						)}
					</div>
				</div>
				<div className='w-full flex'>
					<TableContainer component={Paper}>
						<Table className="w-full" aria-label="customized table">
							<TableHead style={{ backgroundColor: 'gray' }}>
								<TableRow>
									<TableCell>Nombre Archivo</TableCell>
									<TableCell>Number of electrodes</TableCell>
									<TableCell>Distance of electrodes</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{newMeasurements.length && newMeasurements.map((file, i) => (
									<TableRow>
										<TableCell id={i}>{file}</TableCell>
										<TableCell><input type="text" id={i} placeholder='Número de electrodos' onChange={event => numberElectrodes(event, file)} required /></TableCell>
										<TableCell><input type="text" id={i} placeholder='Distancia electrodos' onChange={event => distanceElectrodes(event, file)} required/></TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					className='m-2'
					variant='outlined'
					onClick={async (e) => {
						//ingestMeasumentsFomFtpServer(ftpServer)
						//closeModal(e)
						//closeIngest()
						createJsonToSend(myListEl, myListDis)
					}}
				>
					Importar
					<Icon
						className="w-5 h-5 text-center m-auto"
						path={mdiDatabaseImport}
						title='Siguiente'

					></Icon>
				</Button>

				<Button
					color='primary'
					className='m-2'
					variant='outlined'
					onClick={() => {
						//reload()
						//onClose()
						closeIngest()
					}}
				>
					Salir
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default InjectFiles



