import React from 'react'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '@mdi/react'
import GeoSinergiaLogo from '../../assets/images/GeoSinergiaLogo.svg'
import useStyles from '../../styles/appStyles'

import { mdiAlertOctagon, mdiCalendarCheck, mdiExitToApp, mdiFeatureSearch, mdiServer } from '@mdi/js'

import { NavLink } from 'react-router-dom'
import { logout } from '../../services/auth'
import { Routes } from '../../config/Routes'

const FunctionsMenu: React.FC = () => {
	const classes = useStyles(true)()

	return (
		<>
			<Drawer
				variant='permanent'
				anchor='left'
				open
				className={`drawerstyle ${classes.drawer}`}
			>
				<div
					className={`${classes.toolbarContent} flex flex-col justify-center`}
				>
					<img
						src={GeoSinergiaLogo}
						className='m-auto'
						width='40px'
						alt='Geosinergia'
					/>
				</div>
				<Divider />
				<Grid
					container
					direction='column'
					justifyContent='space-between'
					alignItems='stretch'
					style={{ height: '100%' }}
				>
					<Grid item>
						<List component='nav' aria-label='main mailbox folders'>
							<Tooltip
								title='Procesamiento'
								placement='right'
								aria-label='add'
							>
								<ListItem
									button
									key='li_monit'
									component={NavLink}
									to={Routes.Monitoring}
								>
									<ListItemIcon className='f_menu_icon'>
										<Icon
											path={mdiFeatureSearch}
											title=''
											size={1}
											color='white'
										/>
									</ListItemIcon>
								</ListItem>
							</Tooltip>
						</List>
					</Grid>
					<Grid item>
						<List component='nav' aria-label='main mailbox folders'>
							<Tooltip
								title='Alertas'
								placement='right'
								aria-label='add'
							>
								<ListItem
									button
									key='li_alert'
									component={NavLink}
									to={Routes.Alerts}
								>
									<ListItemIcon className='f_menu_icon'>
										<Icon
											path={mdiAlertOctagon}
											title=''
											size={1}
											color='white'
										/>
									</ListItemIcon>
								</ListItem>
							</Tooltip>
						</List>
					</Grid>
					{/* <Grid item>
						<List component='nav' aria-label='main mailbox folders'>
							<Tooltip
								title='Documentos'
								placement='right'
								aria-label='add'
							>
								<ListItem
									button
									key='li_repo'
									component={NavLink}
									to={Routes.Repository}
								>
									<ListItemIcon className='f_menu_icon'>
										<Icon
											path={mdiFolderStarMultiple}
											title=''
											size={1}
											color='white'
										/>
									</ListItemIcon>
								</ListItem>
							</Tooltip>
						</List>
					</Grid> */}
					<Grid item>
						<List
							component='nav'
							aria-label='main mailbox folders'>
							<Tooltip
								title='Orígenes de datos'
								placement='right'
								aria-label='add'
							>
								<ListItem
									button
									key='li_origins'
									component={NavLink}
									to={Routes.Servers}
								>
									<ListItemIcon className='f_menu_icon'>
										<Icon
											path={mdiServer}
											title=''
											size={1}
											color='white'
										/>
									</ListItemIcon>
								</ListItem>
							</Tooltip>
						</List>
					</Grid>
					<Grid item>
						<List
							component='nav'
							aria-label='main mailbox folders'>
							<Tooltip
								title='Tareas Programadas'
								placement='right'
								aria-label='add'
							>
								<ListItem
									button
									key='li_tasks'
									component={NavLink}
									to={Routes.Tasks}
								>
									<ListItemIcon className='f_menu_icon'>
										<Icon
											path={mdiCalendarCheck}
											title=''
											size={1}
											color='white'
										/>
									</ListItemIcon>
								</ListItem>
							</Tooltip>
						</List>
					</Grid>
					<Grid item>
						<Tooltip
							title='CERRAR SESIÓN'
							placement='right'
							aria-label='add'
							onClick={logout}
						>
							<ListItem button>
								<ListItemIcon className='f_menu_icon'>
									<Icon
										path={mdiExitToApp}
										title=''
										size={1}
										color='white'
										className='drawerIcon'
									/>
								</ListItemIcon>
							</ListItem>
						</Tooltip>
					</Grid>
				</Grid>
			</Drawer>
		</>
	)
}
export default FunctionsMenu
