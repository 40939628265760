import axios from 'axios'
import { MeasurementActions } from '../reducers/MeasurementReducer'
import store from '../store'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL

export const fetchMeasurements = async () => {
	store.dispatch({
		type: MeasurementActions.RequestMeasurements,
		payload: {},
	})
	const state = store.getState().measurmentReducer
	if (!(!state.next && state.measurements.length)) {
		if (state.next) {
			const response = await axios.get<Paginated<Measurement>>(
				state.next,
				{ headers: authHeader() }
			)
			store.dispatch({
				type: MeasurementActions.AddMeasurements,
				payload: response.data,
			})
		} else {
			const response = await axios.get<Paginated<Measurement>>(
				API_URL + 'measurement/',
				{ headers: authHeader() }
			)
			store.dispatch({
				type: MeasurementActions.AddMeasurements,
				payload: response.data,
			})
		}

		store.dispatch({
			type: "SET_SELECTEDMEASUREMENT",
			payload: 0,
		});
	}
}

export const fetchSingleMeasurement = async (id: number) => {
	store.dispatch({
		type: MeasurementActions.RequestMeasurement,
		payload: {},
	})
	const response = await axios.get<Measurement>(
		API_URL + 'measurements/' + id,
		{ headers: authHeader() }
	)
	store.dispatch({
		type: MeasurementActions.AddMeasurement,
		payload: response.data,
	})
}

export const fetchMeasurmentInversions = async (id: number) => {
	return (
		await axios.get<Paginated<Inversion>>(
			API_URL + `measurements/${id}/inversions/`,
			{ headers: authHeader() }
		)
	).data.results
}

export const fetchMeasurementCRS = async (id: number) => {
	const crsString = (await axios.get<string>(
		API_URL + `measurements/${id}/crs/`,
		{ headers: authHeader() }
	)
	).data
	return JSON.parse(crsString) as CRSMeasurement[]
}

export const fetchMeasurementStatuses = async () => {
	const { data } = await axios.get<Paginated<Status>>(
		API_URL + 'measurement_statuses/',
		{ headers: authHeader() }
	)
	store.dispatch({
		type: MeasurementActions.SetMeasurementStatuses,
		payload: data.results,
	})
}

export const createInversion = async (measurement_id: number, template_id: number) => {
	const { data } = await axios.get<Paginated<Status>>(
		`${API_URL}measurements/${measurement_id}/create_inversion/?template=${template_id}`,
		{ headers: authHeader() }
	)
	console.log(data)
}

export const deleteMeasurement = async (measurement_id: number) => {
	await axios.delete<Measurement>(
		API_URL + 'measurements/' + measurement_id,
		{ headers: authHeader() }
	).then(
		() => {window.location.reload()}
	)
	store.dispatch({
		type: MeasurementActions.DeleteMeasurement,
		payload: {measurement_id}
	})

}