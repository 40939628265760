import store from '../../store';
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { authHeader } from "../../services/auth";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogTitle,
  DialogContentText,
  Input,
} from "@material-ui/core";
import { addSnackbarNotification } from "../../services/snackbarNotification";
import { MeasurementActions } from '../../reducers/MeasurementReducer';
import { useAppSelector } from '../../hooks/storeHooks';
import { mdiCloudUpload, mdiSync, mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import { InstallationSet, Mines } from '../../types/Mines';
import { MineActions } from '../../reducers/MinesReducer';

interface User {
  id: number;
  username: string;
  email: string;
  mining_projects: {
    id: number;
    name: string;
    users: number[]
  }[];
  // Añade las demás propiedades necesarias del objeto User
}

interface FormData {
  file: any;
  mineId: number;
  installationId: number;
  measureDate: string;
  // Añade las demás propiedades necesarias del objeto MinaData
}

// interface FormData {
//   file: any;
//   idMine: string;
//   user: string;
//   mining_projects: any[];
//   email: string;
//   // Añade las demás propiedades necesarias del objeto FormData
// }

interface Props {
  open: boolean;
  onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}
const FormVTK: React.FC<Props> = ({ open, onClose }) => {

  const mines = useAppSelector((s) => s.minesReducer.mines)
  const API_URL = process.env.REACT_APP_API_URL
  const vtkInputRef = useRef(null);
  const [selectedZipFile, setselectedZipFile] = useState<any>(null);
  const [allowedExtensions, setAllowedExtensions] = useState<string>(null);
  const [loading, setLoading] = useState(false);
  const [isVtkEmpty, setIsVtkEmpty] = useState(true);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [installationsAvailable, setInstallationsAvailable] = useState<InstallationSet[]>([]);

  const [formData, setFormData] = useState<FormData>({
    file: null,
    mineId: -1,
    installationId: -1,
    measureDate: null
  });

  /**
   * 
   * @param event 
   */
  const handleMineChange = (event: React.ChangeEvent<{ value: number }>) => {
    const { value } = event.target;
    const mineSelectedObj = mines.find(item => item.id === value);
    console.log({ mineSelectedObj })

    setInstallationsAvailable(mineSelectedObj.installation_set);


    setAllowedExtensions("")
    setFormData((prevState) => ({
      ...prevState,
      installationId: -1,
      mineId: value,
      measureDate: null
    }));
  };

  /**
 * 
 * @param event 
 */
  const handleInstallationChange = (event: React.ChangeEvent<{ value: number }>) => {
    const { value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      installationId: value,
    }));


    const { zipFileUpload } = installationsAvailable.find(installation => installation.id == value);
    let extensions = ".zip"
    if (!zipFileUpload) {
      extensions = ".zip,.vtk"
    }
    setAllowedExtensions(extensions)
  };

  /**
 * 
 * @param event 
 */
  const handleInputDate = (event: any) => {
    const { value } = event.target;
    console.log(event.target)

    setFormData((prevState) => ({
      ...prevState,
      measureDate: value
    }));
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    const { zipFileUpload } = installationsAvailable.find(installation => installation.id == formData.installationId);

    if (!file || (zipFileUpload && !file.name.endsWith(".zip"))) {
      event.target.value = "";
      setIsVtkEmpty(false);
      setIsFileLoaded(false); // No se ha cargado un archivo
    }
    else {
      setselectedZipFile(file);

      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prevState) => ({
          ...prevState,
          file: URL.createObjectURL(file),
        }));
      };
      reader.readAsText(file);
      setIsVtkEmpty(file.size === 0); // Verificar si el archivo zip está vacío
      setIsFileLoaded(true); // Se ha cargado un archivo
    }
  };

  useEffect(() => {
    isValidForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isVtkEmpty]);

  const isValidForm = () => !!formData.mineId && !!formData.installationId && !!formData.file

  const saveVtkData = () => {
    // Convertir a datos binarios
    const formDataToSend = new FormData();
    setLoading(true);
    formDataToSend.append("file", selectedZipFile); // el el arhivo zip ===  "file"
    formDataToSend.append("mine", formData.mineId.toString()); // es el id de la mina que esta en mining_projects => id === "mine"
    formDataToSend.append("installation_id", formData.installationId.toString()); // es el id de la instalation que esta en installation_set => id === "intasllation"
    console.log({ formData })
    if (formData.measureDate) {
      formDataToSend.append("measureDate", formData.measureDate);
    }

    axios
      .post(`${API_URL}upload/`, formDataToSend, {
        headers: authHeader(),
      })
      .then((response) => {
        // Aquí puedes manejar la respuesta del servicio
        addSnackbarNotification({ message: response.data.message, type: 'success' })
        // agregar a la lista

        // const installation = mines payload.installation.id === state.selectedInstallation.id
        // store.dispatch({
        //   type: MineActions.AddMines,
        //   payload: response.data.result
        // })
        // cerrar modal
        onClose();
      })
      .catch((error) => {
        // Aquí puedes manejar los errores en caso de que la solicitud falle
        const e = error?.response?.data?.message || 'Hubo un error con el servidor';
        addSnackbarNotification({ message: e, type: 'error' });
      }).finally(() => {
        setLoading(false);
      })
  };



  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <span role='h4' style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', marginBottom: '10px', textTransform: 'uppercase' }}>
            <Icon
              path={mdiCloudUpload}
              className='mr-2'
              color='green'
              size={2}
            ></Icon>
            <span>Subir archivo con mediciones</span>
          </span>
        </DialogTitle>
        <DialogContent>

          <div className="flex" style={{ flexDirection: 'column', gap: '20px' }}>

            {/* Selector de mina */}
            <FormControl>
              <InputLabel id="mine-label">Mina</InputLabel>
              <Select
                labelId="mine-label"
                defaultValue={-1}
                value={formData.mineId}
                onChange={handleMineChange}
              >
                {
                  mines.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                }

              </Select>
            </FormControl>

            {/* Selector de instalación */}
            <FormControl>
              <InputLabel id="installation-label">Instalación</InputLabel>
              <Select
                labelId="installation-label"
                value={formData.installationId}
                disabled={!installationsAvailable.length}
                defaultValue={-1}
                onChange={handleInstallationChange}
                autoWidth
              >
                {
                  installationsAvailable.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            {/* daterange */}
            <FormControl>
              <InputLabel id="installation-label2">Fecha de medición</InputLabel>

              <Input type="date" name="date" id="date" onInput={handleInputDate} value={formData.measureDate} />
            </FormControl>

            {
              allowedExtensions ? <p>Archivos permitidos: {allowedExtensions}</p> : ''
            }
            {/* Botón para agregar .zip */}
            <div
              className="m-2 flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <input
                type="file"
                accept={allowedExtensions}
                ref={vtkInputRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              <Button
                onClick={() => vtkInputRef.current.click()}
                color="primary"
                className="m-2"
                variant='text'
                disabled={!allowedExtensions}
              >
                <Icon
                  className='text-primary hover:opacity-100'
                  path={mdiUpload}
                  size={1}
                ></Icon>
                Seleccionar
              </Button>
              <span style={{ fontSize: 'small' }}>
                {isFileLoaded ? (
                  isVtkEmpty ? (
                    <span style={{ color: "red", margin: "0px 10px" }}>
                      El archivo está vacío. Por favor, carga otro archivo.
                    </span>
                  ) : (
                    <span style={{ margin: "0px 10px" }}>
                      seleccionado: {selectedZipFile.name}
                    </span>
                  )
                ) : (
                  <span style={{ margin: "0px 10px" }}>
                    No se ha cargado ningún archivo.
                  </span>
                )}
              </span>
            </div>

            {/* Botón guardar */}
            <div
              className="m-2 flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Button
                onClick={saveVtkData}
                color="primary"
                className="m-2"
                variant="contained"
                disabled={!isValidForm() || loading}
              >
                {loading ? (
                  <div className='w-7 ml-2'>
                    <Icon
                      className='w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100'
                      path={mdiSync}
                      spin
                    ></Icon>
                  </div>) : ''
                }
                <span>Guardar</span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormVTK;
