import axios from 'axios'
import { InversionActions } from '../reducers/InversionReducer'
import store from '../store'
import { authHeader } from './auth'
import { addSnackbarNotification } from './snackbarNotification'
const API_URL = process.env.REACT_APP_API_URL

export const selectInversion = async (inversion: Inversion) => {
	inversion &&
		store.dispatch({
			type: InversionActions.SelectInversion,
			payload: inversion,
		})
}

export const getVtkFile = async (inversion: Inversion, min_scale: number, max_scale: number, curves: number, labelCount: number, isCont: boolean, numColors: number) => {
	try{
		const { data } = await axios.get(
			API_URL + `inversions/${inversion.id}/vtk`,
			{ headers: authHeader(),
			params: {min_scale: min_scale,
			max_scale: max_scale,
			curves: curves,
			labelCount: labelCount,
			isCont: isCont,
			numColors: numColors,
			},
		    }
		)
		store.dispatch({
			type: InversionActions.AddVtkFile,
			payload: { id: inversion.id, vtkFile: data },
		})
		store.dispatch({
			type: InversionActions.AddImageVtk,
			payload: data,
		})

	}catch(error){
		addSnackbarNotification({message: error.toString() + ` Hubo un error al recuperar el archivo vtk`, type: 'error' })
	}
	
}

// export const getVtkFile = async (inversion: Inversion) => {
// 	if (
// 		inversion &&
// 		!store.getState().inversionReducer.vtkFiles[inversion.id]
// 	) {
// 		const { data } = await axios.get(
// 			API_URL + `inversions/${inversion.id}/vtk`,
// 			{ headers: authHeader()}
// 		)
// 		store.dispatch({
// 			type: InversionActions.AddVtkFile,
// 			payload: { id: inversion.id, vtkFile: data },
// 		})
// 	}
// }

export const fetchInversionStatuses = async () => {
	const { data } = await axios.get<Paginated<Status>>(
		API_URL + 'inversion_statuses/',
		{ headers: authHeader() }
	)
	store.dispatch({
		type: InversionActions.SetInversionStatuses,
		payload: data.results,
	})
}

export const fetchInversions = async () => {
	store.dispatch({
		type: InversionActions.RequestInversions,
		payload: {},
	})
	const state = store.getState().inversionReducer
	if (!(!state.next && state.inversions.length)) {
		if (state.next) {
			const response = await axios.get<Paginated<Inversion>>(
				state.next,
				{ headers: authHeader() }
			)
			store.dispatch({
				type: InversionActions.SetInversions,
				payload: response.data,
			})
		} else {
			const response = await axios.get<Paginated<Inversion>>(
				API_URL + 'inversions/',
				{ headers: authHeader() }
			)
			store.dispatch({
				type: InversionActions.SetInversions,
				payload: response.data,
			})
		}
	}
}
export const fetchAllInversions = async () => {
	store.dispatch({
		type: InversionActions.RequestInversions,
		payload: {},
	})
	var response = await axios.get<Paginated<Inversion>>(
		API_URL + 'inversions/',
		{ headers: authHeader() }
	)
	store.dispatch({
		type: InversionActions.SetInversions,
		payload: response.data,
	})

	while (response.data.next) {
		response = await axios.get<Paginated<Inversion>>(
			response.data.next,
			{ headers: authHeader() }
		)
		store.dispatch({
			type: InversionActions.SetInversions,
			payload: response.data,
		})
	}
}

export const fetchSingleInversion = async (id: number) => {
	store.dispatch({
		type: InversionActions.RequestSingleInversion,
		payload: {},
	})
	const response = await axios.get<Inversion>(
		API_URL + 'inversions/' + id,
		{ headers: authHeader() }
	)
	store.dispatch({
		type: InversionActions.RemoveSingleInversion,
		payload: response.data.id,
	})
	store.dispatch({
		type: InversionActions.AddSingleInversion,
		payload: response.data,
	})
}

export const deleteInversion = async(inversion_id: number) => {
	await axios.delete<Inversion>(
		API_URL + 'inversions/'+inversion_id, {
			headers: authHeader()}
	).then(
		() => {window.location.reload()}
	)
	store.dispatch({
		type: InversionActions.DeleteInversion,
		payload: {inversion_id}
	})
}
