import { Button, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core"
import { useEffect, useState } from "react"
import DeleteButton from "../../components/shared/DeleteButton"
import { useAppSelector } from "../../hooks/storeHooks"
import { fetchAllInversions, selectInversion } from "../../services/inversion"
import { fetchObservationAreas, fetchObservations, fetchObservationSets, fetchObservationTypes, fetchObservationVariations, selectObservationArea, selectObservationSet } from "../../services/observations"
import useStyles from "../../styles/appStyles"
import InversionAlertViewer from "./InversionAlertViewer"
import ObservationHistoryModal from "./ObservationHistoryModal"

const Alerts: React.FC = () => {
  const inversions = useAppSelector((s) => s.inversionReducer.inversions)
  const selectedInversion = useAppSelector(s => s.inversionReducer.selectedInversion)
  const [height] = useState(8)
  const [width] = useState(32)
  const [variationType, setVariationType] = useState<'mean' | 'punctual'>('mean')
  const observationSets = useAppSelector((s) => s.observationReducer.observationSets)
  const selectedObservationSetId = useAppSelector((s) => s.observationReducer.selectedObservationSetId)
  const [selectedObservationSet, setSelectedObservationSet] = useState(null)
  const observationTypes = useAppSelector((s) => s.observationReducer.observationTypes)
  const observationAreas = useAppSelector((s) => s.observationReducer.observationAreas)
  const [observationHistoryModalOpen, setObservationHistoryModalOpen] = useState(true)

  useEffect(() => {
    fetchAllInversions()
    fetchObservationSets()
    fetchObservationTypes()
    fetchObservationAreas()
    fetchObservations()
    fetchObservationVariations()
    !selectedInversion && selectInversion(inversions.slice(-1)[0])
    selectedObservationSetId && setSelectedObservationSet(observationSets.find((obs_set) => obs_set.id === selectedObservationSetId))
  }, [])

  useEffect(() => {
    selectedObservationSetId && setSelectedObservationSet(observationSets.find((obs_set) => obs_set.id === selectedObservationSetId))
  }, [selectedObservationSetId])

  const classes = useStyles(true)()

  return <div className={`${classes.bodyContent} flex`}>
    <div className="w-1/6 h-full flex flex-col">
      <div className="text-gray-700 flex text-lg text-center justify-between items-center p-2">Sets
        {/* <Button color="primary" variant="contained">Nuevo</Button> */}
      </div>
      <div className="flex flex-1 flex-col overflow-auto">
        {observationSets.map((obs_set, i) =>
          <div
            key={'obs_set_' + i}
            className={`border-b hover:bg-gray-300 transition-all p-2`}
            onClick={() => {
              selectObservationSet(obs_set.id)
              selectObservationArea(observationAreas.find((area) => area.id === obs_set.area))
            }}
          >
            {selectedObservationSetId === obs_set.id ? '> ' : ''}{obs_set.name}
          </div>
        )}
      </div>
    </div>
    <div className="w-5/6 flex p-4 flex-col">
      <InversionAlertViewer gridWidth={width} gridHeight={height}></InversionAlertViewer>
      <div className="flex-1 flex overflow-auto">
        <div className="w-1/2 h-full flex flex-col">
          <div className="text-gray-700 flex text-lg text-center justify-between items-center p-2">Destinatarios
            {/* <Button color="primary" variant="contained">Nuevo Destinatario</Button> */}
          </div>
          <div className="flex flex-col overflow-auto flex-1">
            {new Array(4)
              .fill(0)
              .map((_, i) => <div
                key={i}
                className="border-b hover:bg-gray-400 transition-all border-gray-200 p-2 flex justify-between"
              >
                <div>Destinatario{i}@geosinergia.cl</div>
                <span>
                  <DeleteButton
                    onClick={() => { }}
                  ></DeleteButton>
                </span>
              </div>)}
          </div>
        </div>
        <div className="w-1/2 h-full flex flex-col">
          <div className="flex items-center text-lg">
            <TextField
              id="filled-full-width"
              label="% Variación"
              style={{ margin: 8 }}
              value={selectedObservationSet ? selectedObservationSet.threshold : ''}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="filled"
            />

            <RadioGroup className="flex" name="variationType" value={variationType} onChange={e => setVariationType(e.target.value as 'mean' | 'punctual')}>
              <div className="flex ml-2">
                {observationTypes.map((obs_type) =>
                  <FormControlLabel value={obs_type.id} control={<Radio color="primary" checked={selectedObservationSet && obs_type.id === selectedObservationSet.obs_type} />} label={obs_type.name} />
                )}
              </div>
            </RadioGroup>
          </div>
          <div className="flex justify-end text-lg">
            <Button 
            color="primary"
            variant="contained"
            disabled={!selectedObservationSetId}
            onClick={() => setObservationHistoryModalOpen(true)}
            >
              Ver Histórico
            </Button>
          </div>
        </div>
      </div>

    </div>
    <ObservationHistoryModal
      open={observationHistoryModalOpen}
      onClose={() => { setObservationHistoryModalOpen(false) }}
    ></ObservationHistoryModal>
  </div>
}

export default Alerts