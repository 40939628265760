import { Reducer } from 'react'
import { FtpServer } from '../types/FtpServer'

interface State {
	ftpServers: FtpServer[]
	isLoading: boolean
	next: null | string
	prev: null | string
}

const initialState: State = {
	ftpServers: [],
	isLoading: false,
	next: null,
	prev: null,
}


export enum FtpServerActions {
	RequestFtpServers = 'REQUEST_FTPSERVERS',
	RequestFtpServer = 'REQUEST_FTPSERVER',
	AddFtpServers = 'ADD_FTPSERVERS',
	AddFtpServer = 'ADD_FTPSERVER',
	DeleteFtpServer = "DeleteFtpServer"
}

const reducer: Reducer<State, { type: FtpServerActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case FtpServerActions.RequestFtpServers:
			return { ...state, isLoading: true }
		case FtpServerActions.AddFtpServers:
			return {
				...state,
				isLoading: false,
				next: payload.next,
				prev: payload.prev,
				ftpServers: [...state.ftpServers, ...payload],
			}
		case FtpServerActions.AddFtpServer:
			return {
				...state,
				isLoading: false,
				ftpServers: [...state.ftpServers.filter((server) => server.id !== payload.id), payload],
			}
		case FtpServerActions.DeleteFtpServer:
			return {
				...state,
				isLoading: false,
				ftpServers: [...state.ftpServers.filter((server) => server.id !== payload.id)],
			}
		default:
			return state
	}
}

export default reducer
