import './App.css'
import './style.css'
import React, { useEffect, useState } from 'react'

import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom'
import TopBar from './components/shared/TopBar'
import FunctionsMenu from './components/shared/FunctionsMenu'
import useStyles from './styles/appStyles'
import { useAppSelector } from './hooks/storeHooks'
import ProtectedRoute from './components/utilities/ProtectedRoute'
import Login from './views/Login/Login'
import Theme from './components/utilities/Theme'
import { Routes } from './config/Routes'
import Monitoring from './views/Monitoring/Monitoring'
import Servers from './views/Servers/Servers'
import SnackbarNotificationContainer from './components/utilities/SnackbarNotificationContainer'
import { w3cwebsocket as WebSocketClient } from 'websocket'
import store from './store'
import { LoggingActions } from './reducers/LoggingReducer'
import { fetchSingleInversion } from './services/inversion'
import Tasks from './views/Tasks/Tasks'
import Alerts from './views/Alerts/Alerts'
import FileRepository from './views/FileRepository/FileRepository'
import { getUser, initLogin } from './services/auth'
import { MeasurementActions } from './reducers/MeasurementReducer'
import { MineActions } from './reducers/MinesReducer'
import { InstallationSet, Measurement } from './types/Mines'

export const loggingSocket = (): WebSocketClient => {
	const API_URL = process.env.REACT_APP_API_URL
	const socket = new WebSocketClient(
		API_URL.replace('http', 'ws').replace('api/', '') +
		'ws/logging/'
	)
	return socket
}

const App: React.FC = () => {
	const isAuthenticated = useAppSelector((s) => !!s.authReducer.token)
	const classes = useStyles(isAuthenticated)()
	const [socket, setSocket] = useState(null)
	const [isConnected, setIsConnected] = useState(false)

	useEffect(() => {
		isAuthenticated && configSocket()
		isAuthenticated && getUser()
	}, [isAuthenticated])

	useEffect(() => {
		initLogin()
	}, [])

	useEffect(() => {
		if (isAuthenticated && socket) {

			socket.onpen = function () {
				console.log("socket on open")
			}
			socket.onclose = function () {
				console.log("socket on close")
				setIsConnected(false)
			}
			socket.onmessage = function (e: any) {
				setIsConnected(socket?.readyState === 1)
				if (e.data) {
					const message = JSON.parse(e.data)
					store.dispatch({
						type: LoggingActions.PushLogging,
						payload: { message_type: message.message_type, message: JSON.stringify(message), datetime: (new Date()).toString() },
					})
					console.log({ message })
					const updateMines = () => {
						const state = store.getState();
						const mine = state.minesReducer.mines.find(minex => minex.id == message.data.mine_id);
						const installationx = mine.installation_set.find(installation => installation.id == (message.data?.installation_id || message.data?.installation.id));

						if (state.measurmentReducer.selectedInstallation?.id == installationx.id) {
							console.log('instalación seleccionada es la misma del socket, actualizando estación seleccionada')
							store.dispatch({
								type: MeasurementActions.SelectInstallation,
								payload: installationx,
							});

							store.dispatch({
								type: MeasurementActions.SetMeasurements,
								payload: installationx.measurements,
							});

							store.dispatch({
								type: "SET_SELECTEDMEASUREMENT",
								payload: 0,
							});
						}
					}
					switch (message.message_type) {
						case 'object_event':
							switch (message.object_type) {
								case 'Installation': {
									switch (message.action) {
										case 'Updated': {
											// Actualizar estación
											const data = message.data as { installation: InstallationSet; mine_id: number };
											store.dispatch({
												type: MineActions.UpdateInstallation,
												payload: data
											})
											updateMines()
											
											break;
										}
									}
									break;
								}
								case 'Measurement':
									const data = message.data as { measurement: Measurement; mine_id: number; installation_id: number };
									switch (message.action) {
										case 'Updated':
											store.dispatch({
												type: MineActions.UpdateMine,
												payload: data
											})

											updateMines()
											// actualizar medicion
											break
										case 'Deleted':
											// eliminar medicion
											store.dispatch({
												type: MineActions.DeleteMeasureToMine,
												payload: data
											})
											updateMines()
											break
										case 'Created':
											// crear medicion
											store.dispatch({
												type: MineActions.AddMeasureToMine,
												payload: data
											});

											updateMines()
											break
									}
									break
								case 'Inversion':
									switch (message.action) {
										case 'Updated':
											fetchSingleInversion(message.object_id)
											break
										case 'Created':
											break
									}
									break
								default:
									break
							}
							break
						case 'logging':
							break
						default:
							break
					}
				}

			}
		}
	}, [socket, isAuthenticated])

	const configSocket = () => {
		setSocket(loggingSocket())
	}

	return (
		<Theme>
			<div className={classes.root}>
				<BrowserRouter>
					{!isAuthenticated && <Redirect to={Routes.Login} />}
					{isAuthenticated && <TopBar reconnect={configSocket} connected={isConnected} />}
					{isAuthenticated && <FunctionsMenu />}
					<div className={classes.content}>
						<div
							className={isAuthenticated ? classes.toolbar : ''}
						></div>
						<Switch>
							<ProtectedRoute path={Routes.Monitoring} exact>
								<Monitoring></Monitoring>
							</ProtectedRoute>
							<ProtectedRoute path={Routes.Alerts} exact>
								<Alerts></Alerts>
							</ProtectedRoute>
							<ProtectedRoute path={Routes.Repository} exact>
								<FileRepository></FileRepository>
							</ProtectedRoute>
							<ProtectedRoute path={Routes.Servers} exact>
								<Servers></Servers>
							</ProtectedRoute>
							<ProtectedRoute path={Routes.Tasks} exact>
								<Tasks></Tasks>
							</ProtectedRoute>

							<Route exact path={Routes.Login}>
								<Login />
							</Route>
						</Switch>
					</div>
				</BrowserRouter>
				<SnackbarNotificationContainer></SnackbarNotificationContainer>
			</div>
		</Theme>
	)
}

export default App
