import { Reducer } from 'react'

interface State {
	selectedInversion: Inversion
	inversions: Inversion[]
	inversionStatuses: Status[]
	vtkFiles: { [id: number]: string }
	isLoading: false,
	next: null,
	prev: null,
	vtkFileImage: File,
}

const initialState: State = {
	selectedInversion: null,
	inversions: [],
	inversionStatuses: [],
	vtkFiles: {},
	isLoading: false,
	next: null,
	prev: null,
	vtkFileImage: null,
}

export enum InversionActions {
	SelectInversion = 'SELECT_INVERSION',
	SetInversionStatuses = 'SET_INVERSION_STATUSES',
	RequestInversions = 'REQUEST_INVERSIONS',
	RequestSingleInversion = 'REQUEST_SINGLE_INVERSION',
	SetInversions = 'SET_INVERSIONS',
	AddSingleInversion = 'ADD_SINGLE_INVERSION',
	RemoveSingleInversion = 'REMOVE_SINGLE_INVERSION',
	AddVtkFile = 'ADD_VTK_FILE',
	AddImageVtk = "ADD_IMAGE_VTK",
	DeleteInversion = 'DELETE_INVERSION'
}

const reducer: Reducer<State, { type: InversionActions; payload: any }> = (
	state = initialState,
	{ type, payload }
) => {
	switch (type) {
		case InversionActions.SelectInversion:
			return {
				...state,
				selectedInversion: payload,
			}
		case InversionActions.SetInversionStatuses:
			return {
				...state,
				inversionStatuses: payload,
			}
		case InversionActions.AddVtkFile:
			return {
				...state,
				vtkFiles: state.vtkFiles[payload.id]
					? state.vtkFiles
					: { ...state.vtkFiles, [payload.id]: payload.vtkFile },
			}
		case InversionActions.RequestInversions:
			return {
				...state,
				// selectedInversion: payload,
			}
		case InversionActions.SetInversions:
			const tmpInversions = state.inversions.filter((i) => payload.results.map((pi) => pi.id).indexOf(i.id) === -1)
			const total = [...tmpInversions, ...payload.results]
			return {
				...state,
				inversions: total,
			}
		case InversionActions.RemoveSingleInversion:
			return {
				...state,
				inversions: state.inversions.filter(element => element.id !== payload),
			}
		case InversionActions.AddSingleInversion:
			return {
				...state,
				inversions: [...state.inversions, payload],
			}
		case InversionActions.AddImageVtk:
			return{
				...state,
				vtkFileImage: payload
			}
		case InversionActions.DeleteInversion:
			return{
				...state,
				inversion: payload
			}
		default:
			return state
	}
}

export default reducer
