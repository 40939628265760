import { Reducer } from 'react'

interface State {
	scaleId: number
	scaleName: string
	scale: ScaleStep[]
}

const initialState: State = {
	scaleId: null,
	scaleName: '',
	scale:[]
}

export enum ScaleActions {
	RequestScale = 'REQUEST_SCALE',
	SetScale = 'SET_SCALE',
}

const reducer: Reducer<State, { type: ScaleActions; payload: any }> = (
	state = initialState,
	{ type, payload }
) => {
	switch (type) {
		case ScaleActions.SetScale:
			return {
				...state,
				scaleId: payload.id,
				scaleName: payload.name,
				scale: payload.steps,
			}
		default:
			return state
	}
}

export default reducer
