import axios from 'axios'
import { IntervalScheduleActions } from '../reducers/IntervalScheduleReducer'
import store from '../store'
import { IntervalSchedule } from '../types/IntervalSchedule'
import { authHeader } from './auth'
import { addSnackbarNotification } from './snackbarNotification'

const API_URL = process.env.REACT_APP_API_URL

export const fetchIntervalSchedules = async () => {
	store.dispatch({
		type: IntervalScheduleActions.RequestIntervalSchedules,
		payload: {},
	})
	const state = store.getState().intervalReducer
	if (!(!state.next && state.intervals.length)) {
		if (state.next) {
			const response = await axios.get<Paginated<IntervalSchedule>>(
				state.next,
				{ headers: authHeader() }
			)
			store.dispatch({
				type: IntervalScheduleActions.AddIntervalSchedules,
				payload: response.data,
			})
		} else {
			const response = await axios.get<Paginated<IntervalSchedule>>(
				API_URL + 'tasks/intervals/',
				{ headers: authHeader() }
			)
			store.dispatch({
				type: IntervalScheduleActions.AddIntervalSchedules,
				payload: response.data,
			})
		}
	}
}

// export const createIntervalSchedule = async (intervals: IntervalSchedule) => {
// 	var retVal = false
// 	try {
// 		const { data } = await axios.post<IntervalSchedule>(
// 			API_URL + 'intervalss/intervalss/',
// 			intervals,
// 			{ headers: authHeader() }
// 		)
// 		console.log(data)
// 		store.dispatch({
// 			type: IntervalScheduleActions.AddIntervalSchedule,
// 			payload: data,
// 		})
// 		addSnackbarNotification({ message: ` Servidor ${intervals.name} creado`, type: 'success' })
// 		retVal = true
// 	} catch (error) {
// 		addSnackbarNotification({ message: error.toString() + ` Hubo un error al crear el servidor ${intervals.name}`, type: 'error' })

// 	}
// 	return retVal
// }

// export const deleteIntervalSchedule = async (id: number) => {
// 	const response = await axios.delete<IntervalSchedule>(
// 		API_URL + 'file_origins/ftp_intervalss/' + id,
// 		{ headers: authHeader() }
// 	)
// 	store.dispatch({
// 		type: IntervalScheduleActions.DeleteIntervalSchedule,
// 		payload: { id },
// 	})
// }
export const fetchSingleIntervalSchedule = async (id: number) => {
	store.dispatch({
		type: IntervalScheduleActions.RequestIntervalSchedule,
		payload: {},
	})
	const response = await axios.get<IntervalSchedule>(
		API_URL + 'tasks/intervals/' + id,
		{ headers: authHeader() }
	)
	store.dispatch({
		type: IntervalScheduleActions.AddIntervalSchedule,
		payload: response.data,
	})
}

export const changeIntervalScheduleAttributes = async (interval: IntervalSchedule, properties: Partial<IntervalSchedule>) => {
	try {
		await axios.patch(API_URL + `tasks/intervals/${interval.id}/`,
			properties,
			{ headers: authHeader() }
		)
		addSnackbarNotification({message: `Intervalo ${interval.id} actualizado`})
	} catch (error) {
		addSnackbarNotification({message: `Error al actualizar el intervalo ${interval.id}. \n${error}`, type: 'error'})
	}
}
