const initialState = {
    selectedMeasurement: 0,
};

const reducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case 'SET_SELECTEDMEASUREMENT':
            return {
                ...state,
                selectedMeasurement: action.payload
            };
        default:
            return state;
    }
};

export default reducer;