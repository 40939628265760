// se importa los componentes 
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import Icon from "@mdi/react";
import { listFtpServerPath } from "../../services/ftpServer";
import { FileListItem } from "../../types/FileListItem";
import { FtpServer } from "../../types/FtpServer";
import {
  mdiFileDocumentOutline,
  mdiFolder,
  mdiFolderSearch,
  mdiDatabaseImport,
} from "@mdi/js";


// propiedades de los componentes 
interface Props {
  open: boolean; // open: Es un valor booleano que indica si un componente de diálogo (u otro componente similar) está abierto o cerrado.
  onClose?: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void; // Es una función opcional que se llama cuando el componente de diálogo se cierra.
  ftpServer?: FtpServer; // Es un objeto de tipo FtpServer que representa un servidor FTP.
  updatePath: (path: string) => void; // Es probable que esta función se utilice para manejar cambios en la ruta del servidor FTP.
  signalState: boolean; //  Puede representar si la señal está activada o desactivada. 
  changeSignal: Function; // Esta función probablemente se llame cuando se realice alguna acción relacionada con la señal.
  newMeas: any; // Esta propiedad probablemente se utiliza para almacenar datos o información relacionada con nuevas mediciones
  receiveMeasurements: Function; // Es probable que esta función se llame cuando se reciban nuevas mediciones desde algún origen externo.
}

// En resumen, la función establece varios estados locales utilizando los valores de las propiedades pasadas como argumento. 
// Estos estados se utilizan para controlar y almacenar diferentes valores en el componente. 
// Cada estado se inicializa con un valor predeterminado y se puede actualizar utilizando las 
//funciones correspondientes proporcionadas por React.
const ExploreFtpServerModal: React.FC<Props> = ({
  open,
  onClose,
  ftpServer,
  updatePath,
  changeSignal,
  receiveMeasurements,
}) => {
  const [server] = useState(ftpServer);
  const [currentPath, setCurrentPath] = useState(ftpServer?.path);
  const [files, setFiles] = useState<FileListItem[]>([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [countNew] = useState(0);
  const [newMe] = useState([]);
  const [enableButton, setEnableButton] = useState(true);
  const [enableForPath, setEnableForPath] = useState(false);


  // la función check_new_measurements verifica la longitud del arreglo measurements y el valor de enableForPath
  // para determinar si se debe habilitar o deshabilitar un botón (enableButton). 
  // Si el arreglo de mediciones está vacío o enableForPath es falso, el botón se habilita. 
  // De lo contrario, si hay mediciones en el arreglo y enableForPath es verdadero, el botón se deshabilita.
  // enableButton que boton es este 
  function check_new_measurements(measurements) {
    if (measurements.length === 0 || !enableForPath) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }

  const reload = async () => {
    setIsLoadingFiles(true);
    const tmp_files = await listFtpServerPath(server, currentPath);
    // if (tmp_files != null) {
    //   get_new_measurements(measurements, tmp_files);
    // }
    tmp_files?.length && setFiles(tmp_files);
    setIsLoadingFiles(false);
  };

  useEffect(() => {
    if (server) {
      if (server.path === currentPath) {
        setEnableForPath(true);
      } else {
        setEnableForPath(false);
      }
    }
  }, [currentPath, server]);

  // useEffect(() => {
  //   server && reload();
  // }, [currentPath, server]);

  useEffect(() => {
    check_new_measurements(newMe);
  }, [countNew, newMe]);

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".vtk")) {
      // Aquí puedes hacer algo con el archivo .vtk, como enviarlo a un servidor
      console.log("Archivo .vtk seleccionado:", file);
      setSelectedFile(file);
    } else {
      console.log("Por favor, selecciona un archivo .vtk");
    }
    // Reiniciar el input de archivo para permitir seleccionar el mismo archivo nuevamente
    event.target.value = null;
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogContent className="p-2">
          <div className="w-full flex">
          <div className="w-1/4 p-2">
              <Button
                variant="outlined"
                disabled={currentPath === "/"}
                onClick={() => {
                  setCurrentPath(currentPath.split("/").slice(0, -1).join("/"));
                }}
              >
                Regresar
              </Button>
            </div>
            <div className="w-1/2 p-2">
              <TextField
                id="name"
                fullWidth
                color="primary"
                label="Servidor"
                size="medium"
                value={server.name}
                disabled={true}
                // onChange={handleChange}
              />
              <TextField
                id="name"
                fullWidth
                color="primary"
                label="Ruta"
                size="medium"
                value={currentPath === "" ? "/" : currentPath}
                disabled={true}
                // onChange={handleChange}
              />
            </div>
            <div className="w-1/4 p-2">
              {isLoadingFiles && (
                <div className="w-full">
                  <LinearProgress />
                </div>
              )}
            </div>
            <div className="w-1/4 p-2">
              <p>Hay: {countNew} nuevas mediciones (zip files)</p>
            </div>
            <div className="w-1/4 p-2">
              {!enableForPath && (
                <div className="w-full">
                  <p style={{ color: "red" }}>
                    debe establecer esta ruta para importar!
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex">
            <TableContainer component={Paper}>
              <Table className="w-full" aria-label="customized table">
                <TableHead style={{ backgroundColor: "gray" }}>
                  <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Tamaño</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Acciones</TableCell>
                    <TableCell>Archivo Válido</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.length &&
                    files.map((file) => (
                      <TableRow>
                        <TableCell>
                          {file.is_directory ? (
                            <Icon
                              className="w-5 h-5 text-center m-auto"
                              path={mdiFolder}
                            ></Icon>
                          ) : (
                            <Icon
                              className="w-5 h-5 text-center m-auto"
                              path={mdiFileDocumentOutline}
                            ></Icon>
                          )}
                        </TableCell>
                        <TableCell>{file.filename}</TableCell>
                        <TableCell>{file.size}</TableCell>
                        <TableCell>{file.datetime}</TableCell>
                        {file.is_directory ? (
                          <TableCell>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setCurrentPath(
                                  currentPath + "/" + file.filename
                                );
                              }}
                            >
                              <Icon
                                className="w-5 h-5 text-center m-auto"
                                path={mdiFolderSearch}
                                title="Explorar Carpeta"
                              ></Icon>
                            </Button>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        {file.is_valid_file ? (
                          <TableCell>
                            <strong>
                              <p style={{ color: "green" }}>
                                Archivo válido para la descarga
                              </p>
                            </strong>
                          </TableCell>
                        ) : (
                          <TableCell>
                            {!file.is_directory ? (
                              <strong>
                                <p style={{ color: "red" }}>
                                  Archivo inválido para la descarga
                                </p>
                              </strong>
                            ) : (
                              <p>Carpeta</p>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className="m-2"
            variant="outlined"
            onClick={async () => {
              //ingestMeasumentsFomFtpServer(ftpServer)
              //setInject(true)
              //onClose()
              changeSignal();
              receiveMeasurements(newMe);
            }}
            disabled={enableButton}
          >
            Importar Mediciones
            <Icon
              className="w-5 h-5 text-center m-auto"
              path={mdiDatabaseImport}
              title="Importar mediciones pendientes"
            ></Icon>
          </Button>

          <input
            type="file"
            accept=".vtk"
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <Button onClick={() => fileInputRef.current.click()}
          color="primary" className="m-2" variant="outlined" style={{ fontSize: "1px!important" }}>
            Subir .zip
          </Button>
          {selectedFile && <span>VTK seleccionado: {selectedFile.name}</span>}

          <Button
            color="primary"
            className="m-2"
            variant="outlined"
            onClick={async () => {
              reload();
            }}
          >
            Recargar
          </Button>
          <Button
            color="primary"
            className="m-2"
            variant="contained"
            onClick={() => {
              updatePath(currentPath);
              onClose();
            }}
          >
            Establecer como ruta predeterminada del servidor
          </Button>
        </DialogActions>
      </Dialog>
      {/* <InjectFiles
				open={openInject}
				newMeasurements={currentMe}
			>
			</InjectFiles> */}
    </>
  );
};
export default ExploreFtpServerModal;
