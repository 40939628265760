import {
	Dialog,
	DialogActions,
	DialogContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { useEffect } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import { Observation } from '../../types/Observation'
import { mdiAlertCircle } from '@mdi/js'
import Icon from '@mdi/react'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const ObservationHistoryModal: React.FC<Props> = ({ open, onClose }) => {
	const isEditing = useAppSelector((s) => s.authReducer.isEditing)
	const observations = useAppSelector((s) => s.observationReducer.observations)
	const observationVariations = useAppSelector((s) => s.observationReducer.observationVariations)
	const inversions = useAppSelector((s) => s.inversionReducer.inversions)
	const measurements = useAppSelector((s) => s.measurmentReducer.measurements)
	useEffect(() => {
	}, []);

	const getMeasurement = (observation: Observation) => {
		const measurementId = inversions.find((inv) => inv.id === observation.inversion).measurement
		const measurement = measurements.find((m) => m.id === measurementId)
		return measurement
	}

	const getInversionFromId = (inversionId: number) => {
		return inversions.find((inv) => inv.id === inversionId)
	}

	const getObservationVariation = (observation: Observation) => {
		if (observationVariations) {
			return observationVariations.find((variation) => variation.current === observation.id)
		}
		else {
			return undefined
		}
	}
	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='xl'>
			<DialogContent className='p-2'>
				<div className='w-full'>
					<div className='text-xl font-bold'>
						Histórico de Observaciones
					</div>
					<br />
					<div className=''>
						<TableContainer component={Paper}>
							<Table className="w-full text-sm overflow-auto" aria-label="customized table">
								<TableHead style={{ backgroundColor: 'cornflowerblue' }}>
									<TableRow>
										{isEditing && <TableCell>Id Observación</TableCell>}
										<TableCell>Fichero STG</TableCell>
										{isEditing && <TableCell>Id Inversion </TableCell>}
										{isEditing && <TableCell>Id Template </TableCell>}
										<TableCell>Resultado</TableCell>
										<TableCell>Variación(%)</TableCell>
										<TableCell>Variación(Abs)</TableCell>
										<TableCell>Alerta</TableCell>
										{isEditing && <TableCell>Id Variación</TableCell>}
										{isEditing && <TableCell>Obs Previa</TableCell>}
									</TableRow>
								</TableHead>
								<TableBody>
									{observations.length && observations.map((observation) => (
										<TableRow
											className={`text-sm ${getObservationVariation(observation)?.notified ? 'bg-red-100' : 'bg-green-100'}`}
											key={"tr_o_" + observation.id}>
											{isEditing && <TableCell>{observation.id}</TableCell>}
											{isEditing && <TableCell>{observation.inversion}</TableCell>}
											{isEditing && <TableCell>{getInversionFromId(observation.inversion).template}</TableCell>}
											<TableCell>{observation.result.toFixed(2)}</TableCell>
											<TableCell>
												{getObservationVariation(observation)?.result.toFixed(2)}
											</TableCell>
											<TableCell>
												{getObservationVariation(observation)?.absolute.toFixed(2)}
											</TableCell>
											<TableCell>
												{getObservationVariation(observation)?.notified ? (
													<Icon
														path={mdiAlertCircle}
														className='text-gray-700 hover:text-red-500 cursor-pointer transition-all'
														size={1}
													></Icon>
												) : ''}
											</TableCell>
											{isEditing && <TableCell>
												{getObservationVariation(observation)?.id}
											</TableCell>}
											{isEditing && <TableCell>
												{getObservationVariation(observation)?.previous}
											</TableCell>}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
			</DialogActions>
		</Dialog>
	)
}
export default ObservationHistoryModal



