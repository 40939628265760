import {
	Button,
	TextField,
	Checkbox,
	FormControlLabel
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useAppSelector } from '../../hooks/storeHooks';
import { mdiClose, mdiContentSave, mdiImageEdit, mdiSync, mdiTune } from '@mdi/js';
import Icon from '@mdi/react';
import axios from 'axios';
import { InstallationSet } from '../../types/Mines';

interface Props {
	open: boolean,
	onClose: () => any,
	changeImage: (images: { measure: string; scaleOfColors: string }) => void,
	installation: InstallationSet,
	onLoading: (loading: string) => void;
	onUpdateInstallation: (updateInstallation: boolean) => void;
}
const API_URL = process.env.REACT_APP_API_URL

const VisualSettings: React.FC<Props> = ({ open, onClose, changeImage, installation, onLoading, onUpdateInstallation }) => {
	const selectedMeasurement = useAppSelector((s) => s.selectedMeasurement.selectedMeasurement);
	const selectedInstallation = useAppSelector((s) => s.measurmentReducer.selectedInstallation);
	const [loading, setLoading] = useState('');

	const temporalImg = async () => {
		try {
			setLoading('test')
			onLoading('test')
			const token = localStorage.getItem('token')
			if (token) {

				const queryString = Object.keys(formData).reduce((acc, key) => {
					!('noSendOnFormData' in formData[key]) && (acc += `${key}=${'checked' in formData[key] ? formData[key].checked : formData[key].value}&`);
					return acc;
				}, '?') + `measurement_id=${selectedInstallation.measurements[selectedMeasurement].id}`;

				const { data } = await axios.post<{ images: { measure: string; scaleOfColors: string } }>(
					API_URL + `upload/temporal_img/${queryString}`,
					null, { headers: { Authorization: 'token ' + token } }
				);

				changeImage(data.images)
				// store.dispatch({ type: AuthActions.SetToken, payload: token })
			}

		} catch (error) {
			console.log({ error })
		} finally {
			setLoading('')
			onLoading(loading)
		}
	}


	const installationUpdate = async () => {
		try {
			setLoading('apply')
			onLoading('apply')
			const token = localStorage.getItem('token')
			if (token) {
				const body = Object.keys(formData).reduce((acc, key) => {
					!('noSendOnFormData' in formData[key]) && (acc[key] = 'checked' in formData[key] ? formData[key].checked : formData[key].value);
					return acc;
				}, {});
				await axios.put<{ image_url: string }>(
					API_URL + `installation-update/${selectedInstallation.id}/`,
					body, { headers: { Authorization: 'token ' + token } }
				);
				
			}

			onUpdateInstallation(true);

		} catch (error) {
			console.log({ error })
		} finally {
			setLoading('')
			onLoading('')
		}
	}

	const setInput = (target: any) => {
		const valueKey = (target.type === 'checkbox') ? 'checked' : 'value'
		formData[target.name][valueKey] = target[valueKey];

		if (target.name === 'cont') {
			formData.numColors.disabled = !formData[target.name][valueKey]
		}

		setFormData({ ...formData });

		console.log(target.type, target.name)

		console.log({ formData })
	}

	const inputs = {
		ohmsMin: {
			type: 'number',
			label: 'rango mínimo (ohms)',
			name: 'ohmsMin',
			value: 0
		},
		ohmsMax: {
			type: 'number',
			label: 'rango máximo (ohms)',
			name: 'ohmsMax',
			value: 0
		},
		isocurveLines: {
			type: 'number',
			label: 'Cantidad de Curvas por década',
			name: 'isocurveLines',
			value: 0
		},
		n_labels: {
			type: 'number',
			label: 'Cantidad de Labels',
			name: 'n_labels',
			value: 0
		},
		isLogCurve: {
			type: 'checkbox',
			label: 'Es logarítmico?',
			name: 'isLogCurve',
			checked: false
		},
		cont: {
			type: 'checkbox',
			label: 'Asignar Colores manuales?',
			name: 'cont',
			checked: false,
			noSendOnFormData: true
		},
		numColors: {
			type: 'number',
			label: 'Cantidad de colores',
			name: 'numColors',
			disabled: true,
			value: 0
		},
	}

	const [formData, setFormData] = useState(inputs);

	useEffect(() => {
		console.log('useEffect', { installation })

		const newFormData = { ...formData };
		Object.keys(newFormData).forEach(key => {
			key in installation && (newFormData[key]['checked' in newFormData[key] ? 'checked' : 'value'] = installation[key])
		});

		setFormData({ ...newFormData })

		if (open) {
			const element = document;
			const handleEsc = evt => {
				if (evt.key === 'Escape') {
					onClose()
					element.removeEventListener("keydown", handleEsc, true); // Succeeds
				}
			};
			element.addEventListener('keydown', handleEsc);
		}
	}, [open])

	return (
		<div className="menu-float">
			<div className='menu-topbar'>
				<button type='button' onClick={() => onClose()}>
					<Icon
						path={mdiClose}
						color='red'
						size={1}
					></Icon>
				</button>
			</div>
			<div className='menu-float-title'>
				<Icon
					path={mdiImageEdit}
					color='green'
					size={1}
				></Icon>
				<span>Visualización</span>
			</div>
			<div className='menu-float-content'>
				{Object.keys(formData).map(key => (
					formData[key].type !== 'checkbox' ?
						(<TextField
							key={key}
							type={formData[key].type}
							variant="outlined"
							value={formData[key].value}
							name={key}
							id={key}
							label={formData[key].label}
							onChange={(e) => setInput(e.target)}
							disabled={formData[key].disabled}
						>
						</TextField>)
						:
						(<FormControlLabel
							key={key}
							control={
								<Checkbox
									checked={formData[key].checked}
									onChange={(e) => setInput(e.target)}
									name={key}
								//label="B"
								/>
							}
							label={formData[key].label}
						/>)
				))}
			</div>
			<div className='menu-float-actions'>
				<Button onClick={() => {
					onClose()
				}}>
					<Icon
						path={mdiClose}
						size={1}
						color='red'
					></Icon>
					Cerrar
				</Button>
				<Button
					onClick={temporalImg}
					color="primary"
					className="m-2"
					variant="text"
					disabled={!!loading}
				>
					{loading === 'test' ? (
						<div className='w-7 ml-2'>
							<Icon
								className='w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100'
								path={mdiSync}
								spin
							></Icon>
						</div>) : <Icon
							path={mdiTune}
							size={1}
							color='green'
						></Icon>
					}
					<span>Test</span>
				</Button>
				<Button
					onClick={installationUpdate}
					color="primary"
					className="m-2"
					variant="text"
					disabled={!!loading}
				>
					{loading === 'apply' ? (
						<div className='w-7 ml-2'>
							<Icon
								className='w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100'
								path={mdiSync}
								spin
							></Icon>
						</div>) : <Icon
							path={mdiContentSave}
							size={1}
							color='blue'
						></Icon>
					}
					<span>Aplicar</span>
				</Button>
			</div>
		</div>
	)
}
export default VisualSettings