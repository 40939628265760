import { useState } from 'react'
// import { Document, Page } from 'react-pdf'
import { Button, IconButton } from '@material-ui/core'
import Icon from '@mdi/react'
import { mdiDownload, mdiPdfBox } from '@mdi/js'
import useStyles from '../../styles/appStyles'
import { useSize } from '../../hooks/useSize'
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
    name?: string
    report?: string
}

const PdfViewer: React.FC<Props> = ({ name, report }) => {
    const classes = useStyles(true)()
    const selectedReport = report
    const filename = name
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [container, width] = useSize<HTMLDivElement>()

    // function onDocumentLoadSuccess({ numPages }) {
    //     setNumPages(numPages)
    // }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const downloadFile = (url: string) => {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
    }

    return (
        <div ref={container}>
            <div className='sticky flex'>
                <div className='sticky animate-pulse'>
                    <IconButton
                        color='primary'
                        onClick={() =>
                            selectedReport && downloadFile(selectedReport)
                        }
                    >
                        <Icon
                            path={mdiDownload}
                            className='w-8 h-8'
                            color={'#333'}
                        ></Icon>
                    </IconButton>
                </div>
            {/* </div>
            <div className='w-full flex'> */}
                <Button
                    variant={'outlined'}
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    Anterior
                </Button>
                <p
                style={{padding: '20px'}}>
                    Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                </p>
                <Button
                    variant={'outlined'}
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    Siguiente
                </Button>
            </div>
            <div className='text-center flex justify-center  '>
                <Document
                    file={selectedReport}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} width={1024} />
                </Document>
            </div>
        </div>
    )
}
export default PdfViewer
