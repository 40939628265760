import { Redirect } from 'react-router-dom'
import { useAppSelector } from '../../hooks/storeHooks'
import { useForm } from '../../hooks/useForm'
import { login } from '../../services/auth'
import logo from '../../assets/images/GeoSinergiaLogo.svg'
import ertPortada from '../../assets/images/ert_portada.png'
import { Input, InputAdornment } from '@material-ui/core'
import { mdiEmail, mdiKey } from '@mdi/js'
import Icon from '@mdi/react'
import { mainColor } from '../../styles/styleVars.js'
import { withError } from '../../services/snackbarNotification'

const Login: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [values, _, handleChange] = useForm({
		username: '',
		password: '',
	})

	const isAuthenticated = useAppSelector((s) => !!s.authReducer.token)

	return isAuthenticated ? (
		<Redirect to='/' />
	) : (
		<div className='flex flex-col items-center h-screen justify-around bg-gradient-to-b from-gray-100 to-gray-600 tracking-wider'
			style={{
				backgroundImage: `url(${ertPortada})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: '90% 80%',
				backgroundPosition: 'center',
				// filter: 'blur(5px)',
				filter: 'grayscale(30%)'
			}}>
			<div className='flex flex-col items-center w-2/4'>
				<div className='w-20 bg-main-1 rounded-full p-5'>
					<img src={logo} alt='' />
				</div>
				<div className='my-4 text-xl text-main-1 font-bold'>
					Geosinergia
				</div>
				<form
					className='w-8/12'
					onSubmit={withError((e) => {
						e.preventDefault()
						login(values.username, values.password)
					})}
				>
					<div className='bg-white rounded-xl shadow-sm pt-10 pb-2 px-10 flex flex-col'>
						<div className='text-main-1 text-center pt-3 text-l'>
							INICIO DE SESIÓN
						</div>
						<Input
							type='text'
							placeholder='Usuario'
							id='username'
							value={values.username}
							className='mt-8 w-8/12 mx-auto'
							onChange={handleChange}
							endAdornment={
								<InputAdornment position='end'>
									<Icon
										path={mdiEmail}
										title=''
										size={1}
										color={mainColor}
									/>
								</InputAdornment>
							}
						/>

						<Input
							type='password'
							placeholder='Contraseña'
							id='password'
							value={values.password}
							className='mt-8 w-8/12 mx-auto'
							onChange={handleChange}
							endAdornment={
								<InputAdornment position='end'>
									<Icon
										path={mdiKey}
										title='Password'
										size={1}
										color={mainColor}
									/>
								</InputAdornment>
							}
						/>
						<div className='mt-10 text-sm text-main-1 text-center'>
							¿No recuerdas <b>tu contraseña?</b>
						</div>

						<div className='flex justify-center'>
							<div className='flex-shrink'>
								<button
									className='mt-3 rounded-full bg-main-1 text-white relative top-8 px-8 py-4'
									onClick={withError(() =>
										login(values.username, values.password))
									}
								>
									Ingresar
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}
export default Login
