import axios from 'axios';
import { authHeader } from "../services/auth";

export const vtkPngManual = () => {
  return async (dispatch) => {
    try {
      const headers = authHeader(); // Agregar los encabezados usando la función authHeader()

      const response = await axios.get(
        'http://127.0.0.1:8000/api/measurement/?mining_project_id=1',
        { headers }
      );
      console.log(response.data);
      // Aquí puedes realizar alguna manipulación de los datos si es necesario
      const data = response.data;

      // Despachar una acción exitosa con los datos
      dispatch({ type: 'VTK_PNG_MANUAL_SUCCESS', payload: data });
    } catch (error) {
      // Despachar una acción de error en caso de que falle la solicitud
      dispatch({ type: 'VTK_PNG_MANUAL_ERROR', payload: error.message });
    }
  };
};
