import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { mdiReload } from '@mdi/js';
import Icon from '@mdi/react';
import { useEffect } from 'react'
import EditableLabel from '../../components/shared/EditableLabel';
import { useAppSelector } from '../../hooks/storeHooks'
import { fetchIntervalSchedules } from '../../services/interval';
import { changeTaskAttributes, fetchSingleTask, fetchTasks, runTaskNow } from '../../services/task';
import useStyles from '../../styles/appStyles'

const Tasks: React.FC = () => {
    const isEditing = useAppSelector((s) => s.authReducer.isEditing)
    const tasks = useAppSelector((s) => s.taskReducer.tasks)
    const intervals = useAppSelector((s) => s.intervalReducer.intervals)
    useEffect(() => {
        fetchTasks()
        fetchIntervalSchedules()
    }, [])

    const classes = useStyles(true)()
    return (<>

        <div className={`${classes.bodyContent} p-4 overflow-auto`}>

            <div className='w-full text-center mb-5'>
                Tareas {' '}
            </div>
            <TableContainer component={Paper}>
                <Table className="w-full" aria-label="customized table">
                    <TableHead style={{ backgroundColor: 'gray' }}>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Habilitada</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Descripción</TableCell>
                            <TableCell>Tarea</TableCell>
                            <TableCell>Programación</TableCell>
                            <TableCell>Última ejecución</TableCell>
                            {isEditing && (
                                <TableCell>Acciones</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tasks
                            .sort(
                                (a, b) =>
                                    parseInt(a.name) -
                                    parseInt(b.name)
                            )
                            .map((task) => (
                                <TableRow key={task.id}>
                                    <TableCell>{task.id}</TableCell>
                                    <TableCell>
                                        <Checkbox
                                            color="primary"
                                            checked={task.enabled}
                                            onChange={async (enabled) => {
                                                changeTaskAttributes(
                                                    task, { enabled: !task.enabled, }
                                                ).then(() => {
                                                    fetchSingleTask(task.id)
                                                })
                                            }}
                                            size='medium'
                                            disabled={!isEditing}
                                        />
                                    </TableCell>
                                    <TableCell>{task.name}</TableCell>
                                    <TableCell>
                                        <EditableLabel
                                            value={task.description ? task.description : '-'}
                                            id="description"
                                            label="Descripción"
                                            onSubmit={async (description) => {
                                                changeTaskAttributes(
                                                    task, { description, }
                                                ).then(() => {
                                                    fetchSingleTask(task.id)
                                                })
                                            }}
                                        // permissions={[
                                        //     Permissions.ModifyConditions,
                                        // ]}
                                        />
                                    </TableCell>
                                    <TableCell>{task.task}</TableCell>
                                    <TableCell>
                                        {intervals.length && `${intervals.find((i) => i.id === task.interval_id)?.every} ${intervals.find((i) => i.id === task.interval_id)?.period}`}
                                    </TableCell>
                                    <TableCell>{task.last_run_at}</TableCell>
                                    {isEditing && (
                                        <TableCell>
                                            <Button
                                                variant='outlined'
                                                onClick={() => {
                                                    runTaskNow(task)
                                                    fetchSingleTask(task.id)
                                                }}
                                                title='Ejecutar ahora'
                                            >
                                                <Icon
                                                    className="w-5 h-5 text-center m-auto"
                                                    path={mdiReload}
                                                    title='Ejecutar ahora'

                                                ></Icon>
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </>
    )
}
export default Tasks
