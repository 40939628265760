import { connect, useSelector } from 'react-redux';
import { vtkPngManual } from '../../actions/pngVtkActions';
import store from '../../store';
import { IconButton } from '@material-ui/core';
import { mdiAlertOutline, mdiArrowLeft, mdiArrowRight, mdiCalendarRange, mdiImageEdit } from '@mdi/js';
import Icon from '@mdi/react';
import { useAppSelector } from '../../hooks/storeHooks';
import { useState, useEffect } from 'react';
import VisualSettings from './VisualSettings';

const selectedMeasurement = state => {
  return state.selectedMeasurement.selectedMeasurement;
};

const MyComponent = (props) => {
  const selectedPng = useSelector(selectedMeasurement);
  const measurements = useAppSelector((s) => s.measurmentReducer.measurements);
  const selectedInstallation = useAppSelector((s) => s.measurmentReducer.selectedInstallation);
  const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState('');
  const [image, setImage] = useState({ measure: measurements[0].isocurveUrl, scaleOfColors: selectedInstallation.scaleOfColors });
  console.log('pngVtkViewer: componente actualizado', { measurements, selectedInstallation, selectedPng })

  const selectNewImage = (nextValue: number) => {
    const newImageIndex = Math.max(0, Math.min(selectedPng + nextValue, measurements.length - 1), selectedPng - 1)

    store.dispatch({
      type: "SET_SELECTEDMEASUREMENT",
      payload: newImageIndex,
    });

    setImage({ measure: measurements[newImageIndex]?.isocurveUrl, scaleOfColors: selectedInstallation.scaleOfColors })
  }

  const openOptions = () => {
    setMenu(!menu);
    if (!menu) {
      setImage({ measure: measurements[selectedPng]?.isocurveUrl, scaleOfColors: selectedInstallation.scaleOfColors });
    }
  }

  const close = () => {
    setImage({ measure: measurements[selectedPng]?.isocurveUrl, scaleOfColors: selectedInstallation.scaleOfColors });
    setMenu(!menu);
  }

  const changeImage = (images: { measure: string; scaleOfColors: string }): void => {
    setImage({ measure: `${images.measure}?disableCache=${Date.now()}`, scaleOfColors: `${images.scaleOfColors}?disableCache=${Date.now()}` });
  }

  setTimeout(() => {
    if (image.measure !== measurements[selectedPng]?.isocurveUrl && !menu) {
      setImage({ measure: measurements[selectedPng]?.isocurveUrl, scaleOfColors: selectedInstallation.scaleOfColors });
    }
  }, 300)

  const setUpdateInstallationSuccess = () => {
    setMenu(false)
    console.log('termino de instalación')
  }

  useEffect(() => {
    console.log(image)
  }, [image])

  return (
    <div className='h-full' style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '15px', position: 'relative' }}>
      { selectedInstallation.state === 'updating' ?
        <div className='installation-updating-box'>
          Se esta procesando la actualización de la instalación {selectedInstallation.name}, esta acción puede demorar unos minutos.
        </div> 
        : ''
      }
      <button style={{ position: 'absolute', top: 20, right: 20 }} onClick={() => openOptions()}>
        <Icon
          path={mdiImageEdit}
          color='grey'
          size={3}
        ></Icon>
      </button>
      {
        menu && selectedInstallation.state !== 'updating' ? (
          <VisualSettings
            open={menu}
            onClose={close}
            changeImage={changeImage}
            installation={selectedInstallation}
            onLoading={(e) => setLoading(e)}
            onUpdateInstallation={(e) => setUpdateInstallationSuccess()}
          />
        ) : ''
      }
      <h2 style={{ alignSelf: 'center', fontSize: 'x-large', textTransform: 'uppercase', paddingTop: '10px', textDecoration: 'underline' }}>
        {selectedInstallation?.name}
      </h2>
      {
        loading ? (<div className='lmask'>
          <div className='mask-info'>
            {
              loading === 'apply' ? 'Estamos generando nuevas imágenes para toda la instalación. Esto puede tardar hasta varios minutos, puedes cerrar esta ventana sin perder estos cambios...' : 'Espere mientras configuramos la imagen con lo nuevos valores...'
            }
          </div>
        </div>) : ''
      }
      {
        image.measure ? (
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '70vh' }}>
            <img style={{ margin: image.scaleOfColors ? "auto auto 0 auto" : "auto", height: '80%' }} src={image.measure} alt={measurements[selectedPng]?.measureDate} />
            {image.scaleOfColors ? (<img style={{ margin: "0 auto auto auto", height: '20%' }} src={image.scaleOfColors} alt={measurements[selectedPng]?.measureDate} />) : <span></span>}
          </div>
        ) : <div className='h-full' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Icon
            path={mdiAlertOutline}
            color='red'
            size={4}
          ></Icon>
          <span style={{ fontSize: 'large' }}>Error al cargar la imagen (URL incorrecta)</span>
        </div>
      }
      <div className="carousel-container">
        <div className='carouse-title'>
          <h2 style={{ fontSize: 'large', color: 'white', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
            <Icon
              path={mdiCalendarRange}
              color={'black'}
              size={1}
            ></Icon>
            <span>Fecha medición: {measurements[selectedPng]?.measureDate}</span>
          </h2>
        </div>
        <div style={{ display: 'flex' }}>
          <IconButton
            color='primary'
            disabled={menu || selectedPng === 0}
            onClick={() =>
              selectNewImage(-1)
            }
          >
            <Icon
              path={mdiArrowLeft}
              className='w-8 h-8'
              color={'black'}
            ></Icon>
          </IconButton>
          <div className="carousel-number">
            {selectedPng + 1}
          </div>

          <IconButton
            color='primary'
            disabled={menu || selectedPng === measurements.length - 1}
            onClick={() =>
              selectNewImage(1)
            }
          >
            <Icon
              path={mdiArrowRight}
              className='w-8 h-8'
              color={'black'}
            ></Icon>
          </IconButton>
        </div>
      </div>
    </div>
  );
};


export default connect(null, { vtkPngManual, selectedMeasurement })(MyComponent);
