import { Reducer } from 'react'
import { InstallationSet, Mines } from '../types/Mines';
import store from '../store'
import { MeasurementActions } from './MeasurementReducer';
interface State {
    mines: Mines[];
    isLoading: boolean;
    count: null | number;
    next: null | string;
    prev: null | string;
}

const initialState: State = {
    mines: [],
    isLoading: false,
    count: 0,
    next: null,
    prev: null,
}

export enum MineActions {
    RequestMines = "GET_MINES",
    AddMines = "ADD_MINES",
    AddMine = "ADD_MINE",
    UpdateMines = "UPDATE_MINES",
    UpdateMine = "UPDATE_MINE",
    AddMeasureToMine = "ADD_MEASURE_TO_MINE",
    DeleteMeasureToMine = "DELETE_MEASURE_TO_MINE",
    UpdateInstallation = "UPDATE_INSTALLATION",
}

const reducer: Reducer<State, { type: MineActions; payload: any }> = (
    state: State = initialState,
    { type, payload }
): State => {
    if (payload?.measurement) {

        var minex = state.mines.find(mine => mine.id == payload.mine_id);
        var installationx = minex.installation_set.find(installation => installation.id == payload.installation_id);
    }

    else if (payload?.installation) {
        var minex = state.mines.find(mine => mine.id == payload.mine_id);
        var installationx = minex.installation_set.find(installation => installation.id == payload.installation.id);
    }

    switch (type) {
        case MineActions.RequestMines:
            return { ...state, isLoading: true }
        case MineActions.AddMine:
            return {
                ...state,
                mines: [...state.mines, ...payload.results],
            }
        case MineActions.AddMines:
            return {
                ...state,
                isLoading: false,
                count: payload.count,
                next: payload.next,
                prev: payload.prev,
                mines: [...state.mines, ...payload.results],
            }
        case MineActions.UpdateMines:
            return {
                ...state,
                mines: [...state.mines, ...payload],
            }
        // case MineActions.UpdateMine:
        //     const mine = state.mines.find(mine => mine.id == payload.mine_id);
        //     mine.installation_set.find(installation => installation.id == payload.installation_id);

        //     return {
        //         ...state,
        //         mines: [...state.mines, ...payload],
        //     }
        case MineActions.AddMeasureToMine: {
            installationx.measurements.push(payload.measurement);
            console.log({ measuresTotal: installationx.measurements })

            return {
                ...state,
                mines: [...state.mines],
            }
        }
        case MineActions.UpdateInstallation: {
            Object.assign(installationx, payload.installation as InstallationSet);

            return {
                ...state,
                mines: [...state.mines],
            }
        }
        case MineActions.DeleteMeasureToMine:
            const measurementIndex = installationx.measurements.findIndex(measurement => measurement.id == payload.measurement.id);
            installationx.measurements.splice(measurementIndex, 1);
            console.log({ measuresTotal: installationx.measurements })

            return {
                ...state,
                mines: [...state.mines],
            }
        default:
            return state
    }
}

export default reducer
