import { useState, useEffect, useRef, MutableRefObject } from 'react'

export const useSize = <
	T extends { offsetWidth: number; offsetHeight: number }
>(): [MutableRefObject<T>, number, number, () => void] => {
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const container = useRef<T>()

	const updateSize = () => {
		if (container.current) {
			setWidth(container?.current?.offsetWidth- (container?.current?.offsetWidth/15))
			setHeight(container?.current?.offsetHeight)
		}
	}
	useEffect(() => {
		updateSize()
		window.addEventListener('resize', updateSize)
		return () => window.removeEventListener('resize', updateSize)
	}, [container])

	return [container, width, height, updateSize]
}
