import { Reducer } from 'react'
import { InstallationSet } from '../types/Mines'

interface State {
	measurements: NewMeasurement[]
	isLoading: boolean
	next: null | string
	prev: null | string
	measurementStatuses: Status[],
	selectedInstallation: InstallationSet
}

const initialState: State = {
	measurements: [],
	isLoading: false,
	next: null,
	prev: null,
	measurementStatuses: [],
	selectedInstallation: null
}

export enum MeasurementActions {
	RequestMeasurements = 'REQUEST_MEASURMENTS',
	RequestMeasurement = 'REQUEST_MEASURMENT',
	AddMeasurements = 'ADD_MEASURMENTS',
	AddMeasurement = 'ADD_MEASURMENT',
	SetMeasurementStatuses = 'SET_MEASUREMENT_STATUSES',
	DeleteMeasurement = 'DELETE_MEASUREMENT',
	SetMeasurements = 'SET_MEASUREMENT',
	SelectInstallation = 'SELECT_INSTALLATION'
}

const reducer: Reducer<State, { type: MeasurementActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case MeasurementActions.RequestMeasurements:
			return { ...state, isLoading: true }
		case MeasurementActions.AddMeasurements:
			return {
				...state,
				isLoading: false,
				next: payload.next,
				prev: payload.prev,
				measurements: [...state.measurements, ...payload.results],
			}
		case MeasurementActions.SelectInstallation: {
			return {
				...state,
				selectedInstallation: payload
			}
		}
		case MeasurementActions.SetMeasurements:
			return {
				...state,
				isLoading: false,
				next: payload.next,
				prev: payload.prev,
				measurements: [...payload],
			}
		case MeasurementActions.AddMeasurement: {
			return {
				...state,
				isLoading: false,
				measurements: [...state.measurements, { ...payload }],
			}
		}
		case MeasurementActions.SetMeasurementStatuses:
			return {
				...state,
				measurementStatuses: payload,
			}
		default:
			return state
	}
}

export default reducer
