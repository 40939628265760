import AppBar from '@material-ui/core/AppBar'
import Clock from 'react-live-clock'
import useStyles from '../../styles/appStyles'
import { useAppSelector } from '../../hooks/storeHooks'
import Icon from '@mdi/react'
import { mdiAccount, mdiCog, mdiTableSearch } from '@mdi/js'
import { useEffect, useRef, useState } from 'react'

import { Menu, MenuItem } from '@material-ui/core'
import { loadTimeZones, setTimeZone } from '../../services/time'
import LoggingViewerModal from './LoggingViewerModal'
import SettingsModal from './SettingsModal'
import ReconnectModal from './ReconnectModal'

interface Props {
	connected: boolean
	reconnect: () => void
}

const TopBar: React.FC<Props> = ({ reconnect, connected }) => {
	const classes = useStyles(true)()
	const isEditing = useAppSelector((s) => s.authReducer.isEditing)
	const [timezoneMenuOpen, setTimezoneMenuOpen] = useState(false)
	const timeDisplay = useRef()
	const timeZone = useAppSelector((s) => s.timeReducer.timeZone)
	const user = useAppSelector((s) => s.authReducer.user)
	const [loggingViewerVisible, setLoggingViewerVisible] = useState(false)
	const [settingsVisible, setSettingsVisible] = useState(false)

	useEffect(() => {
		loadTimeZones()
	}, [])

	const timeZones = useAppSelector((s) => s.timeReducer.timeZones)
	//   const alertsPopupVisible = useAppSelector((s)=>s.alertReducer.alertsPopupVisible)

	return (
		<AppBar className={classes.appBar} position='fixed'>
			<div className={`flex align-center p-2 ${classes.toolbarContent}`}>
				<div className='logoadjust'></div>
				<div className='text-gray-900 m-auto inline-flex items-center'>
					<div className='bg-white px-4 py-2 rounded-full text-xl flex gap-2'>
						<Clock
							format={'D/M/YYYY HH:mm:ss'}
							ticking={true}
							timezone={timeZone}
						/>
						<div
							onClick={() => setTimezoneMenuOpen(true)}
							className='cursor-pointer'
							ref={timeDisplay}
						>
							{timeZone}
						</div>
					</div>
				</div>
				{/* <div className='text-gray-900 mt-1 mb-1 ml-3 flex items-center'>
					<div className='bg-white p-2 gap-2 flex items-center rounded-full  text-xl' onClick={() => { }}>
						<Icon path={mdiServerNetwork} size={1} color={connected ? 'green' : 'red'} />
					</div>
				</div>
				<div className='text-gray-900 mt-1 mb-1 ml-3 flex items-center'>
					<div className='bg-white p-2 gap-2 flex items-center rounded-full  text-xl' onClick={() => { }}>
						<Icon path={mdiPackageVariantClosed} size={1} color={connected ? 'green' : 'red'} />
					</div>
				</div> */}
				{isEditing && (<div className='text-gray-900 mt-1 mb-1 ml-3 flex items-center'>
					<button className='p-2 gap-2 flex items-center rounded-full  text-xl' onClick={() => { setLoggingViewerVisible(!loggingViewerVisible) }}>
						<Icon path={mdiTableSearch} size={1} color='black' />
					</button>
				</div>)}
				<div className='text-gray-900 mt-1 mb-1 ml-3 flex items-center'>
					<div className='bg-white p-2 gap-2 flex items-center rounded-full  text-xl' style={{userSelect: 'none'}}>
						{user?.username}
						<Icon path={mdiAccount} size={1} color='black' />
					</div>
				</div>
				<div className='text-gray-600 mt-1 mb-1 ml-3 flex items-center'>
					<div className='flex items-center rounded-full text-2xl'>
						{/* <Switch
							value={isEditing}
							onChange={(e) => setIsEditing(e.target.checked)}
							checked={isEditing}
							color='primary'
						></Switch> */}
						<button onClick={() => setSettingsVisible(true)}>
							<Icon path={mdiCog} size={1} className='mr-2' />
						</button>
					</div>
				</div>
			</div>

			<Menu
				open={timezoneMenuOpen}
				onClose={() => setTimezoneMenuOpen(false)}
				anchorEl={timeDisplay.current}
			>
				{timeZones.map((name) => (
					<MenuItem
						key={name}
						onClick={() => {
							setTimeZone(name)
							setTimezoneMenuOpen(false)
						}}
					>
						{name}
					</MenuItem>
				))}
			</Menu>
			<ReconnectModal
				open={!connected}
				onClose={() => { setLoggingViewerVisible(false) }}
				reconnect={reconnect}
			>
			</ReconnectModal>

			<LoggingViewerModal
				open={loggingViewerVisible}
				onClose={() => { setLoggingViewerVisible(false) }}
			>
			</LoggingViewerModal>
			<SettingsModal
				open={settingsVisible}
				onClose={() => { setSettingsVisible(false) }}
			>
			</SettingsModal>
		</AppBar>
	)
}
export default TopBar
