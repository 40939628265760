import { Reducer } from 'react'
import { Template } from '../types/Template'

interface State {
	templates: Template[]
	configKeys: ConfigKey[]
}

const initialState: State = {
	templates: [],
	configKeys: [],
}

export enum TemplateActions {
	AddTemplate = 'ADD_TEMPLATE',
	SetTemplates = 'SET_TEMPLATES',
	SetConfigKeys = 'SET_CONFIG_KEYS',
	DeleteTemplate = 'DELETE_TEMPLATE'
}

const reducer: Reducer<State, { type: TemplateActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case TemplateActions.AddTemplate:
			return {
				...state,
				templates: state.templates.find((t) => t.id === payload.id)
					? state.templates
					: [...state.templates, payload],
			}
		case TemplateActions.SetTemplates:
			return {
				...state,
				templates: payload,
			}
		case TemplateActions.SetConfigKeys:
			return {
				...state,
				configKeys: payload,
			}
		case TemplateActions.DeleteTemplate:
			return{
				...state,
			templates: payload
			}

		default:
			return state
	}
}

export default reducer
