import axios from 'axios'
import { TemplateActions } from '../reducers/TemplateReducer'
import store from '../store'
import { Item, Template } from '../types/Template'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const fetchTemplate = async (id: number) => {
	const response = await axios.get<Template>(API_URL + `templates/${id}`, {
		headers: authHeader(),
	})
	return response.data
}

export const fetchTemplates = async () => {
	var templates = []
	var response = await axios.get<Paginated<Template>>(
		API_URL + `templates/`,
		{
			headers: authHeader(),
		}
	)
	templates = [...templates, ...response.data.results]
	while (response.data.next) {
		response = await axios.get<Paginated<Template>>(response.data.next, {
			headers: authHeader(),
		})
		templates = [...templates, ...response.data.results]
	}
	store.dispatch({ type: TemplateActions.SetTemplates, payload: templates })
}

export const fetchConfigKeys = async () => {
	var configKeys = []
	var response = await axios.get<Paginated<ConfigKey>>(
		API_URL + `config_keys/`,
		{
			headers: authHeader(),
		}
	)
	configKeys = [...configKeys, ...response.data.results]
	while (response.data.next) {
		response = await axios.get<Paginated<ConfigKey>>(response.data.next, {
			headers: authHeader(),
		})
		configKeys = [...configKeys, ...response.data.results]
	}
	store.dispatch({ type: TemplateActions.SetConfigKeys, payload: configKeys })
}

export const getConfigKey = (id: number) => {
	return store
		.getState()
		.templateReducer.configKeys.find((configKey) => configKey.id === id)
}

export const getTemplate = async (id: number) => {
	const inMemory = store
		.getState()
		.templateReducer.templates.find((t) => t.id === id)
	if (inMemory) return inMemory
	const template = await fetchTemplate(id)
	store.dispatch({ type: TemplateActions.AddTemplate, payload: template })
	return template
}

export const createTemplate = async (template: {
	label: string
	items: Partial<Item>[]
}) => {
	const response = await axios.post<Template>(
		API_URL + 'templates/',
		template,
		{
			headers: authHeader(),
		}
	)
	return response.data
}

export const deleteTemplate = async (id: number) => {
	await axios.delete<Template>(API_URL + `templates/${id}`, {
		headers: authHeader(),
	}).then(
		() => {window.location.reload()}
	)
	store.dispatch({
		type: TemplateActions.DeleteTemplate,
		payload: {id}
	})
	
}
