import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	IconButton,
} from '@material-ui/core'
import { mdiChevronDown, mdiMapMarkerCircle, mdiMenuRight, mdiMinus, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import InversionListItem from './InversionListItem'
import store from '../../store'
import { InstallationSet, Mines } from '../../types/Mines'
import { MeasurementActions } from '../../reducers/MeasurementReducer'
interface Props {
	mine: Mines;
	index: number;
}

const MineExpandable: React.FC<Props> = ({ mine, index }) => {
	const [expanded, setExpanded] = useState(false)
	const [expanded2, setExpanded2] = useState(false)
	const allInversions = useAppSelector((s) => s.inversionReducer.inversions)
	const [inversions, setInversions] = useState<Inversion[]>(null)

	const selectedInstallation = useAppSelector((s) => s.measurmentReducer.selectedInstallation)

	const expand = (isExpanded, mine) => {
		// const state = store.getState().selectedMeasurement;
		// store.dispatch({
		// 	type: "SET_SELECTEDMEASUREMENT",
		// 	payload: index,
		// });

		setExpanded(isExpanded);
	}
	const expand2 = (item: InstallationSet) => {
		// const state = store.getState().selectedMeasurement;
		store.dispatch({
			type: MeasurementActions.SelectInstallation,
			payload: item,
		});

		store.dispatch({
			type: MeasurementActions.SetMeasurements,
			payload: item.measurements,
		});

		store.dispatch({
			type: "SET_SELECTEDMEASUREMENT",
			payload: 0,
		});
	}
	useEffect(() => {
		const fetchAllInversions = async () => {
			setInversions(allInversions.filter(i => i.measurement === mine.id))
		}

		expanded && fetchAllInversions()
	}, [expanded, allInversions, mine])

	return (
		<div className='p-1'>
			<h2 style={{ fontWeight: 'lighter', display: 'flex', userSelect: 'none' }}>
				<Icon
					path={mdiMenuRight}
					className='w-6'
					color='#BBB'
				></Icon>
				<span>Mina #{mine.id}</span>
			</h2>
			<Accordion
				elevation={0}
				expanded={expanded}
				onChange={(_, isExpanded) => {
					expand(isExpanded, mine)
				}}
			>
				<AccordionSummary
					expandIcon={
						<Icon
							path={mdiChevronDown}
							className='w-6'
							color='#BBB'
						></Icon>
					}
				>
					<div className='flex justify-between w-full items-center'>
						<div className='font-medium text-gray-500 flex-1'>

							{/* {measurement.id} - */}
							<div role='h5' style={{ fontWeight: 'bold', textTransform: 'uppercase', display: 'flex' }}>
								<Icon
									path={mdiMapMarkerCircle}
									className='mr-2'
									color='blue'
									style={{ width: '20px', fontWeight: 'normal' }}
								></Icon>
								<span>{mine.name}</span>
							</div>
							<p style={{ fontStyle: 'italic', opacity: 0.8 }}>{mine.metaData}</p>
						</div>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<ul style={{ cursor: 'pointer', padding: 'revert' }}>
						{
							mine.installation_set.map((item, index) => (
								<li key={index} className={`flex justify-between w-full items-center${selectedInstallation?.id == item.id ? ' selected' : ''}`} onClick={() => expand2(item)}>
									<div className='font-medium text-gray-500 flex-1'>
										<h4 style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.9em', borderTop: 'dashed' }}>{item.name}</h4>

										<p style={{ fontStyle: 'italic', opacity: 0.8, fontSize: '0.9em' }}>N° Mediciones: {item.measurements.length}</p>
									</div>
								</li>
							))
						}
					</ul>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}
export default MineExpandable
