import axios from 'axios'
import { RepositoryElementActions } from '../reducers/RepositoryElementReducer'
import store from '../store'
import { authHeader } from './auth'
import { addSnackbarNotification } from './snackbarNotification'
const API_URL = process.env.REACT_APP_API_URL

export const fetchRepositoryElements = async () => {
	const response = await axios.get<Paginated<RepositoryElement>>(API_URL + `repository/`, {
		headers: authHeader(),
	})
	store.dispatch({ type: RepositoryElementActions.SetRepositoryElements, payload: response.data.results })
}

export const fetchRootNodes = async () => {
	const response = await axios.get<RepositoryElement>(API_URL + `repository/root`, {
		headers: authHeader(),
	})
	store.dispatch({ type: RepositoryElementActions.SetRootNodes, payload: response.data })
}


export const createRepositoryElement = async (node: RepositoryElement) => {
	var retVal = false
	try {
		const { data } = await axios.post<RepositoryElement>(
			API_URL + `repository/`,
			node,
			{ headers: authHeader() }
		)
		console.log(data)
		store.dispatch({
			type: RepositoryElementActions.AddRepositoryElement,
			payload: data,
		})
		addSnackbarNotification({ message: ` ${node.element_type === 'd' ? 'Directorio' : 'Archivo'} ${node.name} creado`, type: 'success' })
		retVal = true
	} catch (error) {
		addSnackbarNotification({ message: error.toString() + ` Hubo un error al crear el ${node.element_type === 'd' ? 'Directorio' : 'Archivo'} ${node.name}`, type: 'error' })
	}
	return retVal
}

export const uploadFile = async (node: RepositoryElement) => {
	const formData = new FormData()
	formData.append('file', node.file_content)
	formData.append('name', node.name)
	formData.append('parent', node.parent.toString())
	formData.append('element_type', node.element_type)
	const response = await axios.post(
		API_URL + `repository/`,
		formData,
		{ headers: { ...authHeader(), 'content-type': 'multipart/form-data' } }
	)
	return response
}