import { SackbarNotificationActions } from "../reducers/SnackbarReducer"
import store from "../store"

export const addSnackbarNotification = (notification: {
    message: string, type?: 'error'
    | 'info'
    | 'success'
    | 'warning'
}) => {
    store.dispatch({ type: SackbarNotificationActions.AddSnackbarNotification, payload: { ...notification, id: Date.now() } })
}

export const removeSnackbarNotification = (notification: SnackbarNotification) => {
    store.dispatch({ type: SackbarNotificationActions.RemoveSnackbarNotification, payload: notification.id })
}

export const withError =
	(func: Function, errorMessage?: string) =>
	async (...args: any) => {
		try {
			await func(...args)
		} catch (error) {
			try {
				var errors = JSON.parse(error.request?.response)
				errors.errors && (errors = errors.errors)

				if (errors) {
					Object.keys(errors).forEach((key) => {
						console.log(key)
						!!errors[key] &&
							errors[key].forEach((error) => {
								addSnackbarNotification({
									message: `${key}: ${error}`,
									type: 'error',
								})
							})
					})
				} else {
					addSnackbarNotification({
						type: 'error',
						message: errorMessage ?? 'Ha ocurrido un error',
					})
				}
			} catch (error) {
				addSnackbarNotification({
					type: 'error',
					message: errorMessage ?? 'Ha ocurrido un error',
				})
			}
		}
	}
