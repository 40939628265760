import store from '../store'
import { AuthActions } from '../reducers/AuthReducer'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const login = async (username: string, password: string) => {
	store.dispatch({ type: AuthActions.RequestLogin, payload: {} })
	const response = await axios.post(API_URL + 'api-token-auth/', {
		username,
		password,
	})
	const { token } = response.data
	token && localStorage.setItem('token', token)
	store.dispatch({ type: AuthActions.SetToken, payload: token })
	// console.log("getting user")
	// getUser()
}

export const initLogin = async () => {
	try {
		const token = localStorage.getItem('token')
		if (token) {
			await axios.get<Paginated<Status>>(
				API_URL + 'measurement_statuses/',
				{ headers: { Authorization: 'token ' + token } }
			)
			store.dispatch({ type: AuthActions.SetToken, payload: token })

		}

	} catch (error) {
		localStorage.setItem('token', '')
	}
}

export const logout = () => {
	store.dispatch({ type: AuthActions.Logout, payload: {} })
}

export const getUser = async () => {
	const { data } = await axios.get(
		API_URL + process.env.REACT_APP_PATH_USERS + 'me/',
		{ headers: authHeader() }
	)
	store.dispatch({ type: AuthActions.SetUser, payload: data })
}

export const getUsers = async () => {
	const { data } = await axios.get(
		API_URL + process.env.REACT_APP_PATH_USERS,
		{ headers: authHeader() }
	)
	store.dispatch({ type: AuthActions.SetUsers, payload: data })
}

export const setIsEditing = (isEditing: boolean) => {
	store.dispatch({ type: AuthActions.SetIsEditing, payload: isEditing })
}

export const authHeader = () => {
	const token = store.getState().authReducer.token
	return token ? { Authorization: 'token ' + token } : {}
}

export const getUserById = (id: number) =>
	store.getState().authReducer.users.find((u) => u.id === id)
