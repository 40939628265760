import axios from 'axios'
import { TaskActions } from '../reducers/TaskReducer'
import store from '../store'
import { Task } from '../types/Task'
import { authHeader } from './auth'
import { addSnackbarNotification } from './snackbarNotification'

const API_URL = process.env.REACT_APP_API_URL

export const fetchTasks = async () => {
	store.dispatch({
		type: TaskActions.RequestTasks,
		payload: {},
	})
	const state = store.getState().taskReducer
	if (!(!state.next && state.tasks.length)) {
		if (state.next) {
			const response = await axios.get<Paginated<Task>>(
				state.next,
				{ headers: authHeader() }
			)
			store.dispatch({
				type: TaskActions.AddTasks,
				payload: response.data,
			})
		} else {
			const response = await axios.get<Paginated<Task>>(
				API_URL + 'tasks/tasks/',
				{ headers: authHeader() }
			)
			store.dispatch({
				type: TaskActions.AddTasks,
				payload: response.data,
			})
		}
	}
}

// export const createTask = async (task: Task) => {
// 	var retVal = false
// 	try {
// 		const { data } = await axios.post<Task>(
// 			API_URL + 'tasks/tasks/',
// 			task,
// 			{ headers: authHeader() }
// 		)
// 		console.log(data)
// 		store.dispatch({
// 			type: TaskActions.AddTask,
// 			payload: data,
// 		})
// 		addSnackbarNotification({ message: ` Servidor ${task.name} creado`, type: 'success' })
// 		retVal = true
// 	} catch (error) {
// 		addSnackbarNotification({ message: error.toString() + ` Hubo un error al crear el servidor ${task.name}`, type: 'error' })

// 	}
// 	return retVal
// }

// export const deleteTask = async (id: number) => {
// 	const response = await axios.delete<Task>(
// 		API_URL + 'file_origins/ftp_tasks/' + id,
// 		{ headers: authHeader() }
// 	)
// 	store.dispatch({
// 		type: TaskActions.DeleteTask,
// 		payload: { id },
// 	})
// }
export const fetchSingleTask = async (id: number) => {
	store.dispatch({
		type: TaskActions.RequestTask,
		payload: {},
	})
	const response = await axios.get<Task>(
		API_URL + 'tasks/tasks/' + id,
		{ headers: authHeader() }
	)
	store.dispatch({
		type: TaskActions.AddTask,
		payload: response.data,
	})
}

export const changeTaskAttributes = async (task: Task, properties: Partial<Task>) => {
	try {
		await axios.patch(API_URL + `tasks/tasks/${task.id}/`,
			properties,
			{ headers: authHeader() }
		)
		addSnackbarNotification({ message: `Tearea ${task.name} actualizada` })
	} catch (error) {
		addSnackbarNotification({ message: `Error al actualizar la tarea ${task.name}. \n${error}`, type: 'error' })
	}
}
export const runTaskNow = async (task: Task) => {
	try {
		await axios.get<Task>(
			API_URL + `tasks/tasks/${task.id}/run_now/`,
			{ headers: authHeader() }
		)
		addSnackbarNotification({ message: `Tearea ${task.name} añadida a la cola.` })
	} catch (error) {
		addSnackbarNotification({ message: `Error añadiendo tearea ${task.name} a al cola.`, type:'error' })
	}
}